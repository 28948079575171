import { Navigate, useRoutes } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import CompaniesPage from './pages/CompaniesPage';
import ContactPage from './pages/ContactPage';
import MainPage from './pages/MainPage';
import MediaPage from './pages/MediaPage';
import ProjectsPage from './pages/ProjectsPage';
import AirConditioningPage from './pages/acPage';
import HeatingPage from './pages/heatingPage';
import VentilationsPage from './pages/ventilationsPage';
import WallMounted from './assistant_page/wall_mounted';
import MultiSplit from './assistant_page/multi_split';
import FloorCeiling from './assistant_page/floor_ceiling';
import FloorAir from './assistant_page/floor_air';
import Ducted from './assistant_page/ducted';
import Condensing from './assistant_page/condensing';
import HeatRecovery from './assistant_page/heat_recovery';
import SplitMultiSplit from './assistant_page/SMS';
import CoolingHeating from './assistant_page/CHu';
import AirHandling from './assistant_page/air_handling';
import Compact from './assistant_page/compact';
import AirCurtain from './assistant_page/air_curtain';
import Heater from './assistant_page/heater';
import HeatPump from './assistant_page/heat_pumps';
import AGB from './assistant_page/agb_detail';
import AGC from './assistant_page/agc_detail';
import EVO_S from './assistant_page/evo-s_detail';
import EVO_H from './assistant_page/evo-h_detail';
import EVO_T from './assistant_page/evo-t_detail';
import EvoSCompact from './assistant_page/evo-s-compact';
import EvoTCompact from './assistant_page/evo-t-compact';
import EvoTPlus from './assistant_page/evo_t+';
import KCOPlus from './assistant_page/kco+';
import Blast from './assistant_page/blast';

export default function ComponenRouter() {
  return useRoutes([
    {
      path: '/',
      // element: <DashboardLayout />,
      children: [
        { path: '/', element: <MainPage /> },
        { path: '/About', element: <AboutPage /> },
        { path: '/AirConditioning', element: <AirConditioningPage /> },
        { path: '/Heating', element: <HeatingPage /> },
        { path: '/Ventilations', element: <VentilationsPage /> },
        { path: '/Company', element: <CompaniesPage /> },
        { path: '/Projects', element: <ProjectsPage /> },
        { path: '/Media', element: <MediaPage /> },
        { path: '/Contact', element: <ContactPage /> },
        { path: '/WallMounted', element: <WallMounted /> },
        { path: '/MultiSplit', element: <MultiSplit /> },
        { path: 'Cassette', element: <FloorCeiling /> },
        { path: '/FloorAir', element: <FloorAir /> },
        { path: '/Ducted', element: <Ducted /> },
        { path: '/Condensing', element: <Condensing /> },
        { path: '/HeatRecovery', element: <HeatRecovery /> },
        { path: '/SMS', element: <SplitMultiSplit /> },
        { path: '/CoolingHeating', element: <CoolingHeating /> },
        { path: '/AirHandling', element: <AirHandling /> },
        { path: '/Compact', element: <Compact /> },
        { path: '/AirCurtain', element: <AirCurtain /> },
        { path: '/Heater', element: <Heater /> },
        { path: '/HeatPump', element: <HeatPump /> },
        { path: '/AGB', element: <AGB /> },
        { path: '/AGC', element: <AGC /> },
        { path: '/EVO_S', element: <EVO_S /> },
        { path: '/EVO_H', element: <EVO_H /> },
        { path: '/EVO_T', element: <EVO_T /> },
        { path: '/EVOS_COMPACT', element: <EvoSCompact /> },
        { path: '/EVOT_COMPACT', element: <EvoTCompact /> },
        { path: '/EvoTPlus', element: <EvoTPlus /> },
        { path: '/KCOPlus', element: <KCOPlus /> },
        { path: '/Blast', element: <Blast /> },
      ],
    },
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}
