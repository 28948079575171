import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

ProductModal.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  desc: PropTypes.string,
  desc1: PropTypes.string,
  desc2: PropTypes.string,
  desc3: PropTypes.string,
  desc4: PropTypes.string,
  desc5: PropTypes.string,
  desc6: PropTypes.string,
  desc7: PropTypes.string,
  desc8: PropTypes.string,
  desc9: PropTypes.string,
  desc10: PropTypes.string,
  desc11: PropTypes.string,
  desc12: PropTypes.string,
  desc13: PropTypes.string,
  desc14: PropTypes.string,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  image: PropTypes.string,
  image2: PropTypes.string,
  image3: PropTypes.string,
  image4: PropTypes.string,
  image5: PropTypes.string,
  image6: PropTypes.string,
  image7: PropTypes.string,
  image8: PropTypes.string,
  image9: PropTypes.string,
  image10: PropTypes.string,
  image11: PropTypes.string,
  image12: PropTypes.string,
  image13: PropTypes.string,
  image14: PropTypes.string,
  image15: PropTypes.string,
  image16: PropTypes.string,
  image17: PropTypes.string,
  image18: PropTypes.string,
  image19: PropTypes.string,
  image20: PropTypes.string,
  image21: PropTypes.string,
  image22: PropTypes.string,
  image23: PropTypes.string,
  image24: PropTypes.string,
  power: PropTypes.string,
  heating: PropTypes.string,
  voltage: PropTypes.string,
  flowRange: PropTypes.string,
  size: PropTypes.string,
  href: PropTypes.string,
};

// ... (imports and PropTypes remain unchanged)

export default function ProductModal({
  data,
  title,
  show,
  description,
  desc,
  desc1,
  desc2,
  desc3,
  desc4,
  desc5,
  desc6,
  desc7,
  desc8,
  desc9,
  desc10,
  desc11,
  desc12,
  desc13,
  desc14,
  closeModal,
  image,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  power,
  heating,
  voltage,
  flowRange,
  size,
  dimension,
  href,
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [imageSet, setImageSet] = useState(1);
  const images = [image ?? '', image2 ?? '', image3 ?? '', image4 ?? '', image5 ?? '', image6 ?? ''];
  const images1 = [image7 ?? '', image8 ?? '', image9 ?? '', image10 ?? '', image11 ?? '', image12 ?? ''];
  const images2 = [image13 ?? '', image14, image15, image16, image17, image18];
  const images3 = [image19 ?? '', image20 ?? '', image21 ?? '', image22 ?? '', image23 ?? '', image24 ?? ''];

  const transitionInterval = useRef(null);

  const startImageTransition = () => {
    transitionInterval.current = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2500);
  };

  const stopImageTransition = () => {
    clearInterval(transitionInterval.current);
  };

  const handleButtonClick = (set) => {
    stopImageTransition();
    setCurrentImage(0);
    setImageSet(set);
    startImageTransition();
  };

  useEffect(() => {
    if (show) {
      startImageTransition();
    } else {
      stopImageTransition();
      setCurrentImage(0);
      setImageSet(1);
    }

    return () => stopImageTransition();
  }, [show]);

  function getImageSource(set, index) {
    switch (set) {
      case 1:
        return images[index];
      case 2:
        return images1[index];
      case 3:
        return images2[index];
      case 4:
        return images3[index];
      default:
        return images[index];
    }
  }

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center max-lg:py-16 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-xl:w-10/12 lg:w-5/6 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all flex max-lg:flex-col flex-col-reverse lg:flex-row">
                  <div className="lg:w-2/5 lg:h-1/2 max-sm:h-2/3 flex align-center justify-center relative flex-col">
                    {title && (
                      <div className="w-full h-1/5 p-1 ">
                        <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 ml-4">
                          {title}
                        </Dialog.Title>
                      </div>
                    )}
                    {images[1] ? (
                      <div className="w-full h-full flex align-center justify-center p-3 flex-col">
                        <img
                          className="object-fit max-h-[400px] h-full w-full transition-transform duration-300 transform hover:scale-105"
                          src={getImageSource(imageSet, currentImage)}
                          alt={title}
                          onMouseEnter={stopImageTransition}
                          onMouseLeave={startImageTransition}
                        />
                        <div className="flex w-full flex-row justify-around mt-10">
                          {images[0] !== '' ? (
                            <button
                              onClick={() => handleButtonClick(1)}
                              className="rounded-full w-10 h-10 bg-gray-300 hover:bg-gray-400 border border-gray-500 focus:outline-none"
                            />
                          ) : (
                            <></>
                          )}
                          {images1[0] !== '' ? (
                            <button
                              onClick={() => handleButtonClick(2)}
                              className="rounded-full w-10 h-10 bg-black hover:bg-gray-800 border border-gray-500 focus:outline-none"
                            />
                          ) : (
                            <></>
                          )}
                          {images2[0] !== '' ? (
                            <button
                              onClick={() => handleButtonClick(3)}
                              className="rounded-full w-10 h-10 bg-white hover:bg-gray-100 border border-gray-500 focus:outline-none"
                            />
                          ) : (
                            <></>
                          )}
                          {images3[0] !== '' ? (
                            <button
                              onClick={() => handleButtonClick(4)}
                              className="rounded-full w-10 h-10 bg-stone-700 hover:bg-gray-800 border border-gray-500 focus:outline-none"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="w-full h-full flex align-center justify-center p-3 flex-col">
                        <img
                          className="object-fit max-h-[400px] h-full w-full transition-transform duration-300 transform hover:scale-105"
                          src={images[0]}
                          alt={title}
                        />
                      </div>
                    )}
                  </div>
                  <div className="lg:w-3/5 flex flex-col justify-evenly">
                    {description && <p className="text-sm  ml-4" dangerouslySetInnerHTML={{ __html: description }} />}
                    {description && <hr className="my-4 border-t border-gray-300" />}
                    {desc && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc }} />
                      </div>
                    )}
                    {desc1 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc1 }} />
                      </div>
                    )}
                    {desc2 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc2 }} />
                      </div>
                    )}
                    {desc3 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc3 }} />
                      </div>
                    )}
                    {desc4 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc4 }} />
                      </div>
                    )}
                    {desc5 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc5 }} />
                      </div>
                    )}
                    {desc6 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc6 }} />
                      </div>
                    )}
                    {desc7 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc7 }} />
                      </div>
                    )}
                    {desc8 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc8 }} />
                      </div>
                    )}
                    {desc9 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc9 }} />
                      </div>
                    )}
                    {desc10 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc10 }} />
                      </div>
                    )}
                    {desc11 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc11 }} />
                      </div>
                    )}
                    {desc12 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc12 }} />
                      </div>
                    )}
                    {desc13 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc13 }} />
                      </div>
                    )}
                    {desc14 && (
                      <div className="w-full flex flex-row align-center justify-start ml-3">
                        <img src="dot.svg" alt="mrg" className="w-6 h-6" />
                        <p className="text-xs text-gray-500 ml-4" dangerouslySetInnerHTML={{ __html: desc14 }} />
                      </div>
                    )}
                    {heating && <hr className="my-4 border-t border-gray-300" />}
                    {heating && (
                      <div className="w-full flex justify-center">
                        <div className="w-11/12">
                          <h1 className='mb-4 font-normal'>TECHNICAL DATA</h1>
                          <table className="table-fixed w-full border border-collapse border-black">
                            <thead className="border h-10 border-black">
                              <tr>
                                <th className={`font-normal title-font text-md max-lg:text-[10px] text-center border border-black ${power ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  Power /HP/
                                </th>
                                <th className={`font-normal title-font text-md max-lg:text-[10px] text-center border border-black ${heating ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  Heating Cooling capacity /KW/
                                </th>
                                <th className={`font-normal title-font text-md max-lg:text-[10px] text-center border border-black ${voltage ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  Voltage
                                </th>
                                <th className={`font-normal title-font text-md max-lg:text-[10px] text-center border border-black ${flowRange ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  Flow Range
                                </th>
                                <th className={`font-normal title-font text-md max-lg:text-[10px] text-center border border-black ${size ? "max-sm:w-1/4" : "max-sm:hidden"}`}>Size</th>
                              </tr>
                            </thead>
                            <tbody className="border h-10 border-black">
                              <tr className="border border-black">
                                <td className={`font-normal title-font text-sm max-lg:text-[10px] text-center border border-black ${power ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  {power && <p>{power}</p>}
                                </td>
                                <td className={`font-normal title-font text-sm max-lg:text-[10px] text-center border border-black ${heating ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  {heating && <p>{heating}</p>}
                                </td>
                                <td className={`font-normal title-font text-sm max-lg:text-[10px] text-center border border-black ${voltage ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  {voltage && <p>{voltage}</p>}
                                </td>
                                <td className={`font-normal title-font text-sm max-lg:text-[10px] text-center border border-black ${flowRange ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  {flowRange && <p>{flowRange}</p>}
                                </td>
                                <td className={`font-normal title-font text-sm max-lg:text-[10px] text-center border border-black ${size ? "max-sm:w-1/4" : "max-sm:hidden"}`}>
                                  {size && <p>{size}</p>}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {href && <hr className="my-4 border-t border-gray-300" />}
                    {href && (
                      <div className="flex align-center justify-center w-full bordered">
                        <a
                          href={href}
                          className="text-black w-3/4 align-center justify-center text-center text-cyan-600"
                          title="mrg"
                          target="blank"
                        >
                          Tap to See Technical Data
                        </a>
                      </div>
                    )}
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
