// routes
import * as React from "react";
import Router from './routes';
import Navbar from './layouts/Navbar';

// ----------------------------------------------------------------------

export default function App() {
  
  return (
    <>
     {/* // <BrowserRouter> */}
        <Navbar/>
        <Router />
      {/* </BrowserRouter> */}
    </>
     
  );
}
