import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';

export default function VentilationsPage() {
  const [t, i18n] = useTranslation('common');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const data2 = [
    {
      id: 1,
      title: `Modular Air Handling Unit`,
      image: '/AIU.png',
      headImage: '/AIU.png',
      url: '/AirHandling',
      size: 'w-full h-full object-contain',
    },
    {
      id: 2,
      title: `Compact Air Handling Unit`,
      image: '/CA.png',
      headImage: '/CA.png',
      url: '/Compact',
      size: 'w-full h-full object-contain',
    },

    {
      id: 3,
      title: `Heat Recovery`,
      image: '/KCO+.png',
      headImage: '/KCO+.png',
      url: '/HeatRecovery',
      size: 'w-full h-full object-contain',
    },
  ];
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-10/12">
            <div className="text-center h-full">
              <div className="flex justify-center h-1/3 mb-10">
                <div className="flex max-sm:flex-col justify-between items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`max-sm:w-full max-sm:mb-5 hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-white text-white`}
                  >
                    <h1 className="text-black text-xl title-font font-normal">AIR CONDITIONING</h1>
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`max-sm:w-full max-sm:mb-5 hover:bg-white max-sm:text-md text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-white text-white`}
                  >
                    HEATING
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`max-sm:w-full max-sm:mb-5 hover:bg-white max-sm:text-md text-indigo-900 shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-white text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <NavLink key={'Pages'} to={`${data2.url}`}>
                            <div
                              aria-hidden="true"
                              // onClick={() => openModal2(i)}
                              className="bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                            >
                              <div className="h-3/4 w-full">
                                <div className="relative h-full w-full">
                                  <div className="h-full w-full flex items-center justify-evenly">
                                    <div className="w-full h-full flex items-center justify-center bg-gray-50 hover:bg-white">
                                      <img src={data2.headImage} alt="mrg" className={`${data2.size}`} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="solid" />
                              <div className="h-1/4 w-full flex items-center justify-center">
                                <div className="flex-1 p-5">
                                  <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
