import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

CompaniesModal.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  image: PropTypes.string,
};

export default function CompaniesModal({ title, show, desc, closeModal, image }) {
  const changeImage = (data) => {
    switch (data) {
      case 1:
        return (
          <>
            <div className="mt-6 flex justify-between gap-6">
              <img className="w-full" src="/CompMrg.png" loading="lazy" alt="cp4" />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="mt-6 flex justify-between gap-6">
              <img className="w-full" src="/CompCore.png" loading="lazy" alt="cpc1" />
            </div>
          </>
        );
      default:
        return (
          <>
            <div href="/ing.mn" className="mt-6 underline no-underline hover:underline text-right">
              <a href="https://www.ing.mn/">www.Ing.mn</a>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-3/5 lg:w-3/5 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="">
                    <div className="">
                      <div className="">
                        <div className="">
                          <div aria-hidden="true" className="" />
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>
                          <div className="relative p-3 pt-8 md:p-4 md:pl-6 md:rounded-r-2xl lg:pl-10 lg:p-8">
                            <p className="text-gray-700" dangerouslySetInnerHTML={{__html : desc}}/>
                            {changeImage(image)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
