import React from 'react';

import Slider from 'react-slick';

export default function LogoSlider() {
  // const renderSlides = () =>
  //   [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((num) => (
  //     <div className="container px-10 py-8 mx-auto">
  //       <img src={`/l${num}.jpeg`} className="w-28 object-cover object-right-top" alt="mrg" />
  //       {/* <div data-placeholder className="mr-2 h-20 w-20 rounded-full overflow-hidden relative bg-gray-200"/>
  //           <div className="flex flex-col justify-between">
  //             <div data-placeholder className="mb-2 h-5 w-40 overflow-hidden relative bg-gray-200"/>
  //             <div data-placeholder className="h-10 w-40 overflow-hidden relative bg-gray-200"/>
  //           </div> */}
  //     </div>
  //   ));

  const renderSlides = () =>
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((num) => (
      <div key={num} className="container px-1 py-1 mx-auto">
        <div className="py-1 ">
          <div className="grid grid-cols-3 gap-3 text-gray-700 body-font w-3/4 flex items-center lg:w-3/5 mx-auto flex-row flex-col">
            <div className="flex items-center flex-col px-1 py-5 mx-auto">
              <div className="flex sm:w-26 sm:h-32 h-20 w-20 inline-flex items-center justify-center flex-shrink-0 ">
              <img src={`/l${num}.jpeg`} className="w-28 object-cover object-right-top" alt="mrg" />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    ));

  return (
      <Slider
        dots={false}
        slidesToShow={7}
        infinite={1}
        slidesToScroll={1}
        autoplay={1}
        autoplaySpeed={2000}
        speed={2000}
        cssEase={'linear'}
        swipeToSlide={1}
        responsive={
          [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide:1,
                autoplay:1,
                autoplaySpeed:2000,
                speed:2000
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                swipeToSlide:1,
                autoplay:1,
                autoplaySpeed:2000,
                speed:2000
              }
            },
          ]
        }
      >
        {renderSlides()}
      </Slider>
  );
}
