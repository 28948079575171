import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
// import ProjectModal from '../components/ProjectModal';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function FloorCeiling() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [title, setTitle] = useState('');
  const [href, setHref] = useState('');
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [image, setImage] = useState('');
  const [power, setPower] = useState('');
  const [heating, setHeating] = useState('');
  const [voltage, setVoltage] = useState('');
  const [flowRange, setFlowRange] = useState('');
  const [size, setSize] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
    setTitle('');
    setDesc('');
    setDesc1('');
    setDesc2('');
    setDesc3('');
    setDesc4('');
    setImage('');
    setHref('');
  };
  const closeModal1 = () => {
    setIsOpen1(false);
    setTitle('');
    setDesc('');
    setDesc1('');
    setDesc2('');
    setDesc3('');
    setDesc4('');
    setImage('');
    setHref('');
  };

  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `FXFA-A`,
      image: '/FXFA-A.png',
      description: ``,
      desc: `${t('welcome.FXFA-ADesc')}`,
      desc1: `${t('welcome.FXFA-ADesc1')}`,
      desc2: `${t('welcome.FXFA-ADesc2')}`,
      desc3: `${t('welcome.FXFA-ADesc3')}`,
      desc4: `${t('welcome.FXFA-ADesc4')}`,
      headImage: '/FXFA-A.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXFA-A.html',
      power: ``,
      heating: `2.20-16.00 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `840x840x204,246,288 mm`,
    },
    {
      id: 2,
      headTitle:
        'Mongolia’s current tax system is the source of a package of tax laws that were enacted in 1992 and came into force on January 1, 1993.',
      title: `FXZQ-A`,
      image: '/FXZQ-A.png',
      desc: `${t('welcome.FXZQ-ADesc')}`,
      desc1: `${t('welcome.FXZQ-ADesc1')}`,
      desc2: `${t('welcome.FXZQ-ADesc2')}`,
      desc3: `${t('welcome.FXZQ-ADesc3')}`,
      desc4: `${t('welcome.FXZQ-ADesc4')}`,
      headImage: '/FXZQ-A.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXZQ-A.html',
      power: ``,
      heating: `1.70-6.30 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `700x700x55 mm`,
    },
    {
      id: 3,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `FXFQ-A`,
      image: '/FXFQ-A.png',
      desc: `${t('welcome.FXFQ-ADesc')}`,
      desc1: `${t('welcome.FXFQ-ADesc1')}`,
      desc2: `${t('welcome.FXFQ-ADesc2')}`,
      desc3: `${t('welcome.FXFQ-ADesc3')}`,
      desc4: `${t('welcome.FXFQ-ADesc4')}`,
      headImage: '/FXFQ-A.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXFQ-A.html',
      power: ``,
      heating: `2.20-16.00 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `840x840x204 mm`,
    },
  ];
  const data2 = [
    {
      id: 7,
      headTitle: '',
      title: `4-Way Casette`,
      image: '/4-Way Casette.png',
      description: `More compact, less limitation. Hisense VRF new 4-way cassette will provide you with more possibilities for your space.`,
      desc: `${t('welcome.4WayDesc')}`,
      desc1: `${t('welcome.4WayDesc1')}`,
      desc2: `${t('welcome.4WayDesc2')}`,
      desc3: `${t('welcome.4WayDesc3')}`,
      desc4: ``,
      href: '',
      headImage: '/4-Way Casette.png',
      power: `1.0-6.0`,
      heating: `2.8-18.0 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `840x840x248,298 mm`,
    },
    {
      id: 8,
      headTitle: '',
      title: `Mini 4-Way Casette`,
      image: '/Mini 4-Way Casette.png',
      description: `Mini 4-Way Cassette provides a comfortable and aesthetically pleasing environment, making it the perfect asset for your business.`,
      desc: `${t('welcome.Mini4WayDesc')}`,
      desc1: `${t('welcome.Mini4WayDesc1')}`,
      desc2: `${t('welcome.Mini4WayDesc2')}`,
      desc3: `${t('welcome.Mini4WayDesc3')}`,
      desc4: ``,
      href: '',
      headImage: '/Mini 4-Way Casette.png',
      power: `0.6-2.0`,
      heating: `1.5-6.3 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `570x570x215 mm`,
    },
    {
      id: 9,
      headTitle: ``,
      title: `1-Way Casette`,
      image: '/1-Way Casette.png',
      description: `1-Way Cassette can be perfectly integrated with the interior decoration.`,
      desc: `${t('welcome.1WayDesc')}`,
      desc1: `${t('welcome.1WayDesc1')}`,
      desc2: `${t('welcome.1WayDesc2')}`,
      desc3: `${t('welcome.1WayDesc3')}`,
      desc4: ``,
      href: '',
      headImage: '/1-Way Casette.png',
      power: `0.8-2.5`,
      heating: `2.2-8.0 kW`,
      voltage: `220-240 V`,
      flowRange: ``,
      size: `470x910x192 mm`,
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDescription(data1[id].description);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setImage(data1[id].image);
      setHref(data1[id].href);
      setHeating(data1[id].heating);
      setSize(data1[id].size);
      setFlowRange(data1[id].flowRange);
      setVoltage(data1[id].voltage);
      setPower(data1[id].power);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDescription(data2[id].description);
      setDesc(data2[id].desc);
      setDesc1(data2[id].desc1);
      setDesc2(data2[id].desc2);
      setDesc3(data2[id].desc3);
      setDesc4(data2[id].desc4);
      setHref(data2[id].href);
      setImage(data2[id].image);
      setHeating(data2[id].heating);
      setSize(data2[id].size);
      setFlowRange(data2[id].flowRange);
      setVoltage(data2[id].voltage);
      setPower(data2[id].power);
      setIsOpen1(true);
      console.log('true');
    }
  };

  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">Cassette Type</p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">AIR CONDITIONING</h1>
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    HEATING
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <NavLink
                  key="WM"
                  to="/WallMounted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Wall Mounted</h1>
                </NavLink>
                <NavLink
                  key="MSS"
                  to="/MultiSplit"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Multi-Split Systems
                  </h1>
                </NavLink>
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Cassette type</h1>
                </button>
                <NavLink
                  key="FAC"
                  to="/FloorAir"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Floor Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Ducted"
                  to="/Ducted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Ducted Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Condensing"
                  to="/Condensing"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Air Purifier</h1>
                </NavLink>
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
                <button
                  className={`hover:bg-white mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-2/3 h-2/3" src="daikin.png" alt="mrg" />
                </button>

                <button
                  className={`hover:bg-gray-50 mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 2' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  <img className="w-2/3 h-full fill-slate-600" src="hisense_.png" alt="mrg" />
                </button>
              </div>
              {selectedButton && (
                <>
                   {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data1.headImage}
                                      alt="mrg"
                                      className={`${
                                        data1.id === 6
                                          ? 'w-4/5 h-1/2 bg-gray-50 hover:bg-white'
                                          : 'w-full h-full bg-gray-50 hover:bg-white'
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data2.headImage}
                                      alt="mrg"
                                      className='w-full h-full bg-gray-50 hover:bg-white'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {/* <ProjectModal title={title} show={isOpen} desc={desc} image={image} closeModal={closeModal} /> */}
      <ProductModal
        title={title}
        href={href}
        show={isOpen}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal}
      />
      <ProductModal
        title={title}
        href={href}
        show={isOpen1}
        description={description}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal1}
      />
    </>
  );
}
