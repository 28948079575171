import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function AGB() {
  const [t, i18n] = useTranslation('common');
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center flex flex-col justify-center items-center h-full mb-10">
              <img src="AGB.png" alt="mrg" className="w-1/3 h-1/3 max-sm:h-2/3 max-sm:w-2/3 bg-white object-contain" />
              <p className="text-6xl title-font font-extralight color-white mb-10 w-full">AGB</p>
              <p className="text-xl title-font font-extralight color-white mb-10 w-full">{t(`welcome.AGB-Desc`)}</p>
            </div>
            <div className="flex flex-col justify-start items-start h-full">
              <p className="text-4xl title-font font-extralight color-white mb-10 w-full">Technical Data</p>
              <table className="table-fixed w-full border border-collapse border-black">
                <thead className="border border-black h-10">
                  <tr>
                    <th className="font-normal title-font text-xl text-center border border-black">Parameters</th>
                    <th className="font-normal title-font text-xl text-center border border-black">AGB1</th>
                    <th className="font-normal title-font text-xl text-center border border-black">AGB2</th>
                  </tr>
                </thead>
                <tbody className="border border-black">
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Air Volume</td>
                    <td className="font-normal title-font text-md text-center border border-black">4 000 m3/h</td>
                    <td className="font-normal title-font text-md text-center border border-black">3 500 m3/h</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start pl-5 border border-black">Heater</td>
                    <td className="font-normal title-font text-md text-center border border-black">1-rows</td>
                    <td className="font-normal title-font text-md text-center border border-black">2-rows</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. heating power for medium 90/70°C
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">28 kW</td>
                    <td className="font-normal title-font text-md text-center border border-black">47 kW</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Difference temperature of air with medium 90/70°C
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">19 K</td>
                    <td className="font-normal title-font text-md text-center border border-black">43 K</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. medium temperature
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">110°C</td>
                    <td className="font-normal title-font text-md text-center border border-black row-span-2">110°C</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. medium pressure
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">1,0 MPa</td>
                    <td className="font-normal title-font text-md text-center border border-black">1,0 MPa</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Heater connection</td>
                    <td className="font-normal title-font text-md text-center border border-black">3/4"</td>
                    <td className="font-normal title-font text-md text-center border border-black">3/4"</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Heater capacity</td>
                    <td className="font-normal title-font text-md text-center border border-black">1,2 dm3</td>
                    <td className="font-normal title-font text-md text-center border border-black">2,4 dm3</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Supply for fan</td>
                    <td className="font-normal title-font text-md text-center border border-black">230 V; 50 Hz</td>
                    <td className="font-normal title-font text-md text-center border border-black">230 V; 50 Hz</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Fan motor power</td>
                    <td className="font-normal title-font text-md text-center border border-black">240 W</td>
                    <td className="font-normal title-font text-md text-center border border-black">240 W</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Fan engine IP</td>
                    <td className="font-normal title-font text-md text-center border border-black">54</td>
                    <td className="font-normal title-font text-md text-center border border-black">54</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Max. air stream range</td>
                    <td className="font-normal title-font text-md text-center border border-black">20 m</td>
                    <td className="font-normal title-font text-md text-center border border-black">20 m</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Noise level (in 5 m)</td>
                    <td className="font-normal title-font text-md text-center border border-black">53 db(A)</td>
                    <td className="font-normal title-font text-md text-center border border-black">53 db(A)</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Weight (without medium)</td>
                    <td className="font-normal title-font text-md text-center border border-black">26 kg</td>
                    <td className="font-normal title-font text-md text-center border border-black">28 kg</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">Weight of mixing box</td>
                    <td className="font-normal title-font text-md text-center border border-black">17 kg</td>
                    <td className="font-normal title-font text-md text-center border border-black">17 kg</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
