import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { NavLink } from 'react-router-dom';
import { useScrollDirection } from '../components/useScrollDirection';
import './style.css';

const navigation = [
  { name: 'menuHome', href: '/Home', current: false },
  { name: 'menuAboutUs', href: '/About', current: false },
  { name: 'menuCompany', href: '/Company', current: false },
  {
    name: 'menuProducts',
    current: false,
    href:'/AirConditioning',
    dropdown: [
      { name: 'Air Conditioning', href: '/AirConditioning', current: false },
      { name: 'Heating', href: '/Heating' },
      { name: 'Ventilations', href: '/Ventilations' },
    ],
  },
  { name: 'menuProject', href: '/Projects', current: false },
  { name: 'menuMedia', href: '/Media', current: false },
  { name: 'menuContact', href: '/Contact', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const [t, i18n] = useTranslation('common');
  const ref = useRef();
  const [showSidebar, setShowSidebar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(null); // Updated to use null instead of false

  useEffect(() => {
    const overlayBackElement = document.getElementById('overlayBack');
    if (overlayBackElement) {
      overlayBackElement.className = showSidebar ? 'blur-sm' : '';
    }
  }, [showSidebar]);

  const handleDropdownToggle = (index) => {
    setShowDropdown(index === showDropdown ? null : index);
  };
  const handleDropdownToggleCancel = () => {
    setShowDropdown(null);
  };

  const styles = {
    active: {
      visibility: 'visible',
      transition: 'all 0.5s',
    },
    hidden: {
      visibility: 'hidden',
      transition: 'all 0.5s',
      transform: 'translateY(-100%)',
    },
  };

  return (
    <>
      <div
        ref={ref}
        style={useScrollDirection() === 'up' ? styles.active : styles.hidden}
        className="sticky top-0 left-0 right-0 bg-white z-50 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8"
      >
        <div className="relative flex items-center justify-between h-12">
          <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <NavLink key={'Home'} to={'/Home'}>
                <img className="block lg:hidden h-8 w-auto" src="https://i.imgur.com/gmId995.png" alt="Mrg" />
                <img className="hidden lg:block h-8 w-auto" src="https://i.imgur.com/gmId995.png" alt="Mrg" />
              </NavLink>
            </div>
            <div className="hidden lg:flex items-center justify-center md:flex-1 lg:w-0">
              <div className="flex space-x-4">
                {navigation.map((item, index) => (
                  <React.Fragment key={item.name}>
                    {item.dropdown ? (
                      <div className="relative mt-1.5" onMouseLeave={handleDropdownToggleCancel}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-500 text-white'
                              : 'text-black link link-underline link-underline-black ',
                            'px-3 py-2 rounded-md text-sm font-normal'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                          onMouseEnter={() => handleDropdownToggle(index)}
                        >
                          {t(`welcome.${item.name}`)}
                        </NavLink>
                        {showDropdown === index && (
                          <div
                            className={`absolute z-50 w-48 mt-2 py-2 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-700 dark:border-gray-600`}
                            style={{ left: '0' }}
                          >
                            {item.dropdown.map((dropdownItem) => (
                              <NavLink
                                key={dropdownItem.name}
                                to={dropdownItem.href}
                                className={`block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white`}
                                onClick={() => {
                                  setShowDropdown(null);
                                  setShowSidebar(false);
                                }}
                              >
                                {dropdownItem.name}
                              </NavLink>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        as={NavLink}
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-500 text-white'
                            : 'text-black link link-underline link-underline-black ',
                          'px-3 py-2 rounded-md text-sm font-normal'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {t(`welcome.${item.name}`)}
                      </NavLink>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            {/* <div className="hidden lg:flex items-center justify-end lg:w-0">
              <button
                onClick={() => i18n.changeLanguage('mn')}
                className="text-ff text-gray-600 title-font text-black link link-underline link-underline-black"
              >
                Mn
              </button>
              <p className="text-ff text-gray-600 title-font text-black hover:text-grey">&nbsp;|&nbsp;</p>
              <button
                onClick={() => i18n.changeLanguage('en')}
                className="text-ff text-gray-600 title-font text-black link link-underline link-underline-black"
              >
                En
              </button>
            </div> */}
          </div>
          <div className="lg:hidden flex items-center justify-end">
            <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-black focus:outline-none">
              <span className="sr-only">Open main menu</span>
              {showSidebar ? (
                <XIcon onClick={() => setShowSidebar(!showSidebar)} className="block h-6 w-6 z-40" aria-hidden="true" />
              ) : (
                <MenuIcon onClick={() => setShowSidebar(!showSidebar)} className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0" />
        </div>
      </div>

      <div
        ref={ref}
        className={`top-0 right-0 w-[60%] bg-white p-3 pl-10 text-black fixed h-full z-50  ease-in-out duration-300 ${
          showSidebar ? 'translate-x-0 ' : 'translate-x-full'
        }`}
      >
        <h3 className="">
          <div className="flex items-end justify-end">
            <XIcon onClick={() => setShowSidebar(!showSidebar)} className="block h-6 w-6 z-50" aria-hidden="true" />
          </div>
          <div className="px-1 pt-10 pb-1 space-y-1 text-left text-1xl">
            {navigation.map((item) => (
              <NavLink
                as={NavLink}
                key={item.name}
                to={item.href}
                className={classNames(
                  item.current ? 'text-white text-[20px] ' : 'link link-underline link-underline-black text-[20px]',
                  'block px-3 py-2 font-thin text-[20px]'
                )}
                aria-current={item.current ? 'page' : undefined}
                onClick={() => {
                  setShowSidebar(!showSidebar);
                  setShowDropdown(null);
                }}
              >
                {t(`welcome.${item.name}`)}
              </NavLink>
            ))}
            {/* <div className="relative flex items-center">
              <div className="flex-grow border-t border-gray-400" />
            </div> */}
            {/* <button
              onClick={() => i18n.changeLanguage('en')}
              key={'En'}
              className={classNames('text-black text-[18px] block px-3 py-2 font-thin text-[18px]')}
            >
              English
            </button>
            <button
              onClick={() => i18n.changeLanguage('mn')}
              key={'Mn'}
              className={classNames('text-black text-[18px] block px-3 py-2 font-thin text-[18px]')}
            >
              Mongolian
            </button> */}
          </div>
        </h3>
      </div>
    </>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
