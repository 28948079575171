import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import ProjectModal from '../components/ProjectModal';
import ProductModal from '../components/productModal';
import Footer from '../layouts/Footer';

export default function SplitMultiSplit() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [desc5, setDesc5] = useState('');
  const [desc6, setDesc6] = useState('');
  const [desc7, setDesc7] = useState('');
  const [desc8, setDesc8] = useState('');
  const [desc9, setDesc9] = useState('');
  const [desc10, setDesc10] = useState('');
  const [desc11, setDesc11] = useState('');
  const [desc12, setDesc12] = useState('');
  const [desc13, setDesc13] = useState('');
  const [desc14, setDesc14] = useState('');
  const [image, setImage] = useState('');
  const [href, setHref] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeModalSecond = () => {
    setIsOpenSecond(false);
  };

  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `CYA-DK-F`,
      image: '/cya.png',
      desc: `${t('welcome.CYA-D1')}`,
      desc1: `${t('welcome.CYA-D2')}`,
      desc2: `${t('welcome.CYA-D3')}`,
      desc3: `${t('welcome.CYA-D4')}`,
      desc4: `${t('welcome.CYA-D5')}`,
      desc5: `${t('welcome.CYA-D6')}`,
      headImage: '/cya.png',
      href: 'https://www.daikin.eu/en_us/products/product.table.html/CYA-DK-F.html',
    },
  ];
  const data2 = [
    {
      id: 2,
      headTitle: '',
      title: `SILVER with heater (AG)`,
      image: '/AG.png',
      desc: `${t('welcome.AG-D1')}`,
      desc1: `${t('welcome.AG-D2')}`,
      desc2: `${t('welcome.AG-D3')}`,
      desc3: `${t('welcome.AG-D4')}`,
      desc4: `${t('welcome.AG-D5')}`,
      desc5: `${t('welcome.AG-D6')}`,
      desc6: `${t('welcome.AG-D7')}`,
      desc7: `${t('welcome.AG-D8')}`,
      desc8: `${t('welcome.AG-D9')}`,
      desc9: `${t('welcome.AG-D10')}`,
      desc10: `${t('welcome.AG-D11')}`,
      desc11: `${t('welcome.AG-D12')}`,
      desc12: `${t('welcome.AG-D13')}`,
      desc13: `${t('welcome.AG-D14')}`,
      desc14: `${t('welcome.AG-D15')}`,
      headImage: '/AG.png',
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDesc(data1[id].desc);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setDesc5(data1[id].desc5);
      setImage(data1[id].image);
      setHref(data1[id].href);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDesc(data2[id].desc);
      setDesc1(data2[id].desc1);
      setDesc2(data2[id].desc2);
      setDesc3(data2[id].desc3);
      setDesc4(data2[id].desc4);
      setDesc5(data2[id].desc5);
      setDesc6(data2[id].desc6);
      setDesc7(data2[id].desc7);
      setDesc8(data2[id].desc8);
      setDesc9(data2[id].desc9);
      setDesc10(data2[id].desc10);
      setDesc11(data2[id].desc11);
      setDesc12(data2[id].desc12);
      setDesc13(data2[id].desc13);
      setDesc14(data2[id].desc14);
      setImage(data2[id].image);
      setIsOpenSecond(true);
      console.log('true');
    }
  };
  return (
    <>
      <div className="h-fit w-full bg-gray-100 flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32">
          <div className="my-4 h-full w-10/12 ">
            <div className="text-center h-full">
              <p className="text-6xl title-font font-extralight color-white mb-10">Air Curtain</p>
              <div className="flex justify-center h-1/3 mb-10">
                <button
                  className={`hover:bg-white rounded-l-xl font-bold w-1/3 h-24 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 ${
                    selectedButton === 'Button 1' ? 'bg-white text-white' : 'bg-gray-100 text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-36 h-12" src="daikin.png" alt="mrg" />
                </button>
                <button
                  className={`hover:bg-white rounded-r-xl font-bold w-1/3 h-24 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 ${
                    selectedButton === 'Button 2' ? 'bg-white text-white' : 'bg-gray-100 text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  <img className="w-1/3 h-1/2 fill-slate-600" src="kaisailogo.png" alt="mrg" />
                </button>
              </div>
              <div className="flex justify-center h-1/3 mb-10">
                <NavLink
                  key="HeatRecovery"
                  to="/HeatRecovery"
                  className={`hover:bg-white rounded-l-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-gray-500 hover:text-black text-sm title-font font-normal">Heat Pump</h1>
                </NavLink>
                <button
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-black hover:text-black text-md title-font font-normal">Air Curtain</h1>
                </button>
                <NavLink
                  key="CoolingHeating"
                  to="/CoolingHeating"
                  className={`hover:bg-white rounded-r-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-gray-500 hover:text-black text-sm title-font font-normal">Heater</h1>
                </NavLink>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid grid-cols-3 gap-2 h-2/3 w-full flex">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-white border-r-2 border-b-2 w-full h-96"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative hidden h-full w-full overflow-hidden hidden sm:block">
                                <div className="h-full w-full flex items-center justify-evenly object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100">
                                  <div className="w-full h-2/3 flex items-center justify-center">
                                    {data1.id === 4 ? (
                                      <img src={data1.headImage} alt="mrg" className="w-1/4 h-full" /> // Adjust size for the 4th image
                                    ) : (
                                      <img src={data1.headImage} alt="mrg" className="w-1/2 h-2/3" /> // Default size for other images
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center">
                              <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <ArrowCircleRightIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 p-5 hidden sm:block">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-white border-r-2 border-b-2 w-full h-96"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative hidden h-full w-full overflow-hidden hidden sm:block">
                                <div className=" h-full w-full flex items-center justify-evenly object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100">
                                  <div className="w-full h-2/3 flex items-center justify-center">
                                    <img src={data2.headImage} alt="mrg" className="w-1/2 h-2/3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center">
                              <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <ArrowCircleRightIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 p-5 hidden sm:block">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal2(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ProductModal
        title={title}
        show={isOpen}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        href={href}
        image={image}
        closeModal={closeModal}
      />
      <ProductModal
        title={title}
        show={isOpenSecond}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        desc10={desc10}
        desc11={desc11}
        desc12={desc12}
        desc13={desc13}
        desc14={desc14}
        href={href}
        image={image}
        closeModal={closeModalSecond}
      />
    </>
  );
}
