import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function AGC() {
  const [t, i18n] = useTranslation('common');
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center flex flex-col justify-center items-center h-full mb-10">
              <img src="AGC.png" alt="mrg" className="w-1/3 h-1/3 max-sm:h-2/3 max-sm:w-2/3 bg-white object-contain" />
              <p className="text-6xl title-font font-extralight color-white mb-10 w-full">AGC</p>
              <p className="text-xl title-font font-extralight color-white mb-10 w-full">{t(`welcome.AGC-Desc`)}</p>
            </div>
            <div className="flex flex-col justify-start items-start h-full">
              <p className="text-4xl title-font font-extralight color-white mb-10 w-full">Technical Data</p>
              <table className="table-fixed w-full border border-collapse border-black">
                <thead className="border border-black h-10">
                  <tr>
                    <th className="font-normal title-font text-lg text-center border border-black">Parameters</th>
                    <th className="font-normal title-font text-lg text-center border border-black">Data</th>
                  </tr>
                </thead>
                <tbody className="border border-black">
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-sm text-start border border-black pl-5">Air Volume</td>
                    <td className="font-normal title-font text-md text-center border border-black">3 500 m3/h</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start pl-5 border border-black">Heater</td>
                    <td className="font-normal title-font text-md text-center border border-black">2-rows</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. heating power for medium 90/70°C
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">57 kW</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                    Max. cooling power for medium 6/12°C
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">32 kW</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Difference temperature of air with medium 90/70°C
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">45 K</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. medium temperature
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">110°C</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. medium pressure
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">1,0 MPa</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Coil connection
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">1"</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Coil capacity
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">3,0 dm3</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Supply for fan
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">230 V; 50 Hz</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Fan motor power
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">240 W</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Fan engine IP
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">54</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Max. air stream range
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">20 m</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Noise level (in 5 m)
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">53 db(A)</td>
                  </tr>
                  <tr className="border border-black h-10">
                    <td className="font-normal title-font text-md text-start border border-black pl-5">
                      Weight (without medium)
                    </td>
                    <td className="font-normal title-font text-md text-center border border-black">30 kg</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
