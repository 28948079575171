import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function MultiSplit() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [desc5, setDesc5] = useState('');
  const [desc6, setDesc6] = useState('');
  const [desc7, setDesc7] = useState('');
  const [desc8, setDesc8] = useState('');
  const [desc9, setDesc9] = useState('');
  const [desc10, setDesc10] = useState('');
  const [desc11, setDesc11] = useState('');
  const [desc12, setDesc12] = useState('');
  const [desc13, setDesc13] = useState('');
  const [desc14, setDesc14] = useState('');
  const [image, setImage] = useState('');
  const [power, setPower] = useState('');
  const [heating, setHeating] = useState('');
  const [voltage, setVoltage] = useState('');
  const [flowRange, setFlowRange] = useState('');
  const [size, setSize] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModalSecond = () => {
    setIsOpenSecond(false);
  };

  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `Daikin EMURA`,
      image: '/Emura.png',
      description:
        'Daikin Emura: Unleash modern sophistication with powerful climate control. Elevate your space with the perfect blend of aesthetics and cutting-edge technology.',
      desc: `${t('welcome.DaikinEmuraD1')}`,
      desc1: `${t('welcome.DaikinEmuraD2')}`,
      desc2: `${t('welcome.DaikinEmuraD3')}`,
      desc3: `${t('welcome.DaikinEmuraD4')}`,
      desc4: `${t('welcome.DaikinEmuraD5')}`,
      headImage: '/Emura.png',
      power: '',
      heating: '1.84 - 5.0 kW',
      voltage: '220-240 V',
      flowRange: '13-18m²',
      size: '900x305x212 mm',
    },
    {
      id: 2,
      headTitle: '',
      title: `VISTA Ceiling Cassette`,
      image: '/Vistacassette.png',
      desc: `${t('welcome.vccD1')}`,
      desc1: `${t('welcome.vccD2')}`,
      desc2: `${t('welcome.vccD3')}`,
      desc3: `${t('welcome.vccD4')}`,
      desc4: `${t('welcome.vccD5')}`,
      desc5: `${t('welcome.vccD6')}`,
      desc6: `${t('welcome.vccD7')}`,
      desc7: `${t('welcome.vccD8')}`,
      desc8: `${t('welcome.vccD9')}`,
      desc9: `${t('welcome.vccD10')}`,
      desc10: `${t('welcome.vccD11')}`,
      headImage: '/Vistacassette.png',
      power: '',
      heating: '',
      voltage: '',
      flowRange: '',
      size: '',
    },
    {
      id: 3,
      headTitle: ``,
      title: `FTXS/CTXS`,
      image: '/ftxs ctxs.png',
      desc: `${t('welcome.ftxsctxsD1')}`,
      desc1: `${t('welcome.ftxsctxsD2')}`,
      desc2: `${t('welcome.ftxsctxsD3')}`,
      desc3: `${t('welcome.ftxsctxsD4')}`,
      desc4: `${t('welcome.ftxsctxsD5')}`,
      desc5: `${t('welcome.ftxsctxsD6')}`,
      desc6: `${t('welcome.ftxsctxsD7')}`,
      desc7: `${t('welcome.ftxsctxsD8')}`,
      desc8: `${t('welcome.ftxsctxsD9')}`,
      desc9: `${t('welcome.ftxsctxsD10')}`,
      desc10: `${t('welcome.ftxsctxsD11')}`,
      headImage: '/ftxs ctxs.png',
      power: '',
      heating: '0,040-0,060 kW',
      voltage: '220-240 V',
      flowRange: '',
      size: '1050x290x250 mm',
    },
    {
      id: 4,
      headTitle: '',
      title: `FVXS`,
      image: '/fvxs.png',
      desc: `${t('welcome.fvxsD1')}`,
      desc1: `${t('welcome.fvxsD2')}`,
      desc2: `${t('welcome.fvxsD3')}`,
      desc3: `${t('welcome.fvxsD4')}`,
      desc4: `${t('welcome.fvxsD5')}`,
      desc5: `${t('welcome.fvxsD6')}`,
      desc6: `${t('welcome.fvxsD7')}`,
      desc7: `${t('welcome.fvxsD8')}`,
      desc8: `${t('welcome.fvxsD9')}`,
      desc9: `${t('welcome.fvxsD10')}`,
      headImage: '/fvxs.png',
      power: '',
      heating: '0.015-0.034 kW',
      voltage: '220-240 V',
      flowRange: '',
      size: '700x600x210 mm',
    },
    {
      id: 5,
      headTitle: '',
      title: `FDMQ`,
      image: '/FDMQ.png',
      desc: `${t('welcome.fdmqD1')}`,
      desc1: `${t('welcome.fdmqD2')}`,
      desc2: `${t('welcome.fdmqD3')}`,
      desc3: `${t('welcome.fdmqD4')}`,
      desc4: `${t('welcome.fdmqD5')}`,
      desc5: `${t('welcome.fdmqD6')}`,
      desc6: `${t('welcome.fdmqD7')}`,
      desc7: `${t('welcome.fdmqD8')}`,
      desc8: `${t('welcome.fdmqD9')}`,
      desc9: `${t('welcome.fdmqD10')}`,
      desc10: `${t('welcome.fdmqD11')}`,
      headImage: '/FDMQ.png',
      power: '',
      heating: '',
      voltage: '',
      flowRange: '',
      size: '',
    },
  ];
  const data2 = [
    {
      id: 7,
      headTitle: '',
      title: `VRV IV-S Series`,
      image: '/vrviv-s.png',
      desc: `${t('welcome.vrvD1')}`,
      desc1: `${t('welcome.vrvD2')}`,
      desc2: `${t('welcome.vrvD3')}`,
      desc3: `${t('welcome.vrvD4')}`,
      desc4: `${t('welcome.vrvD5')}`,
      desc5: `${t('welcome.vrvD6')}`,
      desc6: `${t('welcome.vrvD7')}`,
      desc7: `${t('welcome.vrvD8')}`,
      headImage: '/vrviv-s.png',
    },
    {
      id: 8,
      headTitle: '',
      title: `AURORA`,
      image: '/4-port-aurora.png',
      desc: `${t('welcome.auroraD1')}`,
      desc1: `${t('welcome.auroraD2')}`,
      desc2: `${t('welcome.auroraD3')}`,
      desc3: `${t('welcome.auroraD4')}`,
      desc4: `${t('welcome.auroraD5')}`,
      desc5: `${t('welcome.auroraD6')}`,
      desc6: `${t('welcome.auroraD7')}`,
      desc7: `${t('welcome.auroraD8')}`,
      desc8: `${t('welcome.auroraD9')}`,
      desc9: `${t('welcome.auroraD10')}`,
      desc10: `${t('welcome.auroraD11')}`,
      headImage: '/4-port-aurora.png',
    },

    {
      id: 9,
      headTitle: `RMXS Series 8-Zone`,
      title: `RMXS Series 8-Zone`,
      image: '/rmxs series.png',
      desc: `${t('welcome.rmxsD1')}`,
      desc1: `${t('welcome.rmxsD2')}`,
      desc2: `${t('welcome.rmxsD3')}`,
      desc3: `${t('welcome.rmxsD4')}`,
      desc4: `${t('welcome.rmxsD5')}`,
      desc5: `${t('welcome.rmxsD6')}`,
      desc6: `${t('welcome.rmxsD7')}`,
      desc7: `${t('welcome.rmxsD8')}`,
      headImage: '/rmxs series.png',
    },
    {
      id: 10,
      headTitle: `MXS Series`,
      title: `MXS Series`,
      image: '/mxs series.png',
      desc: `${t('welcome.mxsD1')}`,
      desc1: `${t('welcome.mxsD2')}`,
      desc2: `${t('welcome.mxsD3')}`,
      desc3: `${t('welcome.mxsD4')}`,
      desc4: `${t('welcome.mxsD5')}`,
      desc5: `${t('welcome.mxsD6')}`,
      headImage: '/mxs series.png',
    },
    {
      id: 11,
      headTitle: `CIRRA`,
      title: `CIRRA`,
      image: '/cirra.png',
      desc: `${t('welcome.cirraD1')}`,
      desc1: `${t('welcome.cirraD2')}`,
      desc2: `${t('welcome.cirraD3')}`,
      desc3: `${t('welcome.cirraD4')}`,
      desc4: `${t('welcome.cirraD5')}`,
      desc5: `${t('welcome.cirraD6')}`,
      headImage: '/cirra.png',
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDescription(data1[id].description);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setDesc5(data1[id].desc5);
      setDesc6(data1[id].desc6);
      setDesc7(data1[id].desc7);
      setDesc8(data1[id].desc8);
      setDesc9(data1[id].desc9);
      setDesc10(data1[id].desc10);
      setDesc11(data1[id].desc11);
      setDesc12(data1[id].desc12);
      setDesc13(data1[id].desc13);
      setDesc14(data1[id].desc14);
      setImage(data1[id].image);
      setHeating(data1[id].heating);
      setSize(data1[id].size);
      setFlowRange(data1[id].flowRange);
      setVoltage(data1[id].voltage);
      setPower(data1[id].power);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDesc(data2[id].desc);
      setDesc1(data2[id].desc1);
      setDesc2(data2[id].desc2);
      setDesc3(data2[id].desc3);
      setDesc4(data2[id].desc4);
      setDesc5(data2[id].desc5);
      setDesc6(data2[id].desc6);
      setDesc7(data2[id].desc7);
      setDesc8(data2[id].desc8);
      setDesc9(data2[id].desc9);
      setDesc10(data2[id].desc10);
      setImage(data2[id].image);
      setHeating(data2[id].heating);
      setSize(data2[id].size);
      setFlowRange(data2[id].flowRange);
      setVoltage(data2[id].voltage);
      setPower(data2[id].power);
      setIsOpenSecond(true);
      console.log('true');
    }
  };

  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">Multi-Split Systems</p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">AIR CONDITIONING</h1>
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    HEATING
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <NavLink
                  key="WM"
                  to="/WallMounted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Wall Mounted</h1>
                </NavLink>
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Multi-Split Systems</h1>
                </button>
                <NavLink
                  key="CT"
                  to="/Cassette"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Cassette Type</h1>
                </NavLink>
                <NavLink
                  key="FAC"
                  to="/FloorAir"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Floor Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Ducted"
                  to="/Ducted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Ducted Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Condensing"
                  to="/Condensing"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Air Purifier</h1>
                </NavLink>
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
                <button
                  className={`hover:bg-white mr-3 font-normal w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-black' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  Indoor Units
                </button>

                <button
                  className={`hover:bg-gray-50 mr-3 font-normal w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 2' ? ' shadow-xl bg-gray-50 text-black' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  Outdoor Units
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data1.headImage}
                                      alt="mrg"
                                      className="w-full h-full bg-gray-50 object-fit hover:bg-white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data2.headImage}
                                      alt="mrg"
                                      className="w-full h-full bg-gray-50 object-contain hover:bg-white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ProductModal
        title={title}
        show={isOpen}
        description={description}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        desc10={desc10}
        desc11={desc11}
        desc12={desc12}
        desc13={desc13}
        desc14={desc14}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal}
      />

      <ProductModal
        title={title}
        show={isOpenSecond}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        desc10={desc10}
        desc11={desc11}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModalSecond}
      />
    </>
  );
}
