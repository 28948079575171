import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function EvoSCompact() {
  const [t, i18n] = useTranslation('common');
  const [imageIndex, setImageIndex] = useState(0);
  const images = ['EVO-S Compact.png'];

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000);

    return () => clearTimeout(timer);
  }, [imageIndex, images.length]);

  const changeImage = (increment) => {
    if (increment) {
      setImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    } else {
      setImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }
  };
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center flex flex-col justify-center items-center h-full mb-10">
              <div className="w-full h-full flex flex-row justify-evenly items-center">
                {/* <button onClick={() => changeImage(false)} className="text-4xl">
                  <img src="left-arrow.png" alt="mrg" className="w-10 h-10" />
                </button> */}
                <img
                  src={images[imageIndex]}
                  alt="mrg"
                  className="w-1/3 h-1/3 max-lg:w-2/3 max-lg:h-2/3 bg-white object-contain"
                />
                {/* <button onClick={() => changeImage(true)} className="text-4xl">
                  <img src="right-arrow.png" alt="mrg" className="w-10 h-10" />
                </button> */}
              </div>
              <p className="max-sm:text-4xl text-6xl title-font font-extralight color-white mb-5 w-full">
                Klimor EVO-S Compact
              </p>
              <p className="max-sm:text-xl text-3xl title-font font-extralight color-white mb-10 w-full">
                Compact air handling unit
              </p>
              <div className="w-full h-full flex max-sm:flex-col flex-row justify-evenly items-center mb-10">
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the office.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the office</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the hotel.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the hotel</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the restaurant.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the restaurant</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for apartment.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For your apartment</p>
                </div>
              </div>
              <p className="text-xl title-font font-extralight text-black mb-10 w-5/6 max-sm:w-full h-full">
                {t(`welcome.EVO-S-Compact-Desc`)}
              </p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Rigid frame</p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Thermal brake panels</p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Insulation</p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Fan set</p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Heat recovery</p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-5/6 h-full">Anticorrosive coating</p>
            </div>
            <div className="flex flex-col justify-start items-start h-full w-full">
              <p className="text-4xl title-font font-extralight color-white mb-5 w-full">Functions Performed</p>
              <div className="w-full flex flex-row">
                <div className="w-1/3 flex flex-col">
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="PF.svg" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">PF</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Primary filter</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="SF.svg" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">SF</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Secondary filter</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="VF.svg" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">VF</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Fan set</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="RR.svg" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">RR</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Rotary heat exchanger</p>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col">
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="PR.svg" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">PR</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Plate heat exchanger</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="ES.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">ES</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Empty section</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="WH.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">WH</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Water heating coil</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="WC.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">WC</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Water cooling coil</p>
                  </div>
                </div>
                <div className="w-1/3 flex flex-col">
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="DX.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">DX</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">
                      Direct expansion cooling coil
                    </p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="EH.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">EH</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Electrical heater</p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-start bg-gray my-2">
                    <div className="w-1/3 flex flex-row items-center justify-start">
                      <img src="SL.png" alt="mrg" className="w-10 h-10" />
                      <p className="text-4xl title-font font-extralight color-white mx-3 text-indigo-900">SL</p>
                    </div>
                    <p className="w-2/3 text-lg title-font font-extralight color-white mx-5">Silencer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start h-full">
              <p className="text-4xl title-font font-extralight color-white my-10 w-full">Technical Data</p>
              <div className="w-full h-full flex flex-row max-sm:flex-col">
              <table className="table-fixed w-5/12 max-sm:w-full max-sm:mb-10 border border-collapse border-black">
                  <thead className="border border-black h-24">
                    <tr>
                      <th className="font-normal title-font text-lg border border-black">SIZE</th>
                      <th className="font-normal title-font text-lg border border-black">VMIN</th>
                      <th className="font-normal title-font text-lg border border-black">VOPT</th>
                      <th className="font-normal title-font text-lg border border-black">VMAKS</th>
                    </tr>
                  </thead>
                  <tbody className="border border-black">
                    {/* 1 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">5 100</td>
                      <td className="font-normal title-font text-md text-center border border-black">778</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 450</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 499</td>
                    </tr>
                    {/* 2 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">3 200</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 102</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 250</td>
                      <td className="font-normal title-font text-md text-center border border-black">4 957</td>
                    </tr>
                    {/* 3 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">0 300</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 408</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 800</td>
                      <td className="font-normal title-font text-md text-center border border-black">6 334</td>
                    </tr>
                    {/* 4 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">0 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 822</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 750</td>
                      <td className="font-normal title-font text-md text-center border border-black">8 197</td>
                    </tr>
                    {/* 5 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">2 500</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 419</td>
                      <td className="font-normal title-font text-md text-center border border-black">500</td>
                      <td className="font-normal title-font text-md text-center border border-black">10 886</td>
                    </tr>
                    {/* 6 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">0 600</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 851</td>
                      <td className="font-normal title-font text-md text-center border border-black">5 900</td>
                      <td className="font-normal title-font text-md text-center border border-black">12 830</td>
                    </tr>
                    {/* 7 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">0 700</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 326</td>
                      <td className="font-normal title-font text-md text-center border border-black">7 000</td>
                      <td className="font-normal title-font text-md text-center border border-black">14 969</td>
                    </tr>
                    {/* 8 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">5 800</td>
                      <td className="font-normal title-font text-md text-center border border-black">4 082</td>
                      <td className="font-normal title-font text-md text-center border border-black">8 300</td>
                      <td className="font-normal title-font text-md text-center border border-black">18 371</td>
                    </tr>
                    {/* 9 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">0 010</td>
                      <td className="font-normal title-font text-md text-center border border-black">4 666</td>
                      <td className="font-normal title-font text-md text-center border border-black">9 700</td>
                      <td className="font-normal title-font text-md text-center border border-black">20 995</td>
                    </tr>
                    {/* 10 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">5 310</td>
                      <td className="font-normal title-font text-md text-center border border-black">6 487</td>
                      <td className="font-normal title-font text-md text-center border border-black">13 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">29 192</td>
                    </tr>
                    {/* 11 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">5 610</td>
                      <td className="font-normal title-font text-md text-center border border-black">7 934</td>
                      <td className="font-normal title-font text-md text-center border border-black">16 500</td>
                      <td className="font-normal title-font text-md text-center border border-black">35 705</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table-fixed w-7/12 max-sm:w-full border border-collapse border-black">
                  <thead className="border border-black h-8">
                    <tr>
                      <th className="font-normal title-font text-lg border border-black" colSpan="6">
                        EXTERNAL DIMENSIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody className="border border-black">
                    <tr className="border border-black h-8">
                      <td
                        className="font-normal title-font text-sm text-center border border-black pl-5 h-8"
                        colSpan="3"
                      >
                        SUPPLY OR EXHAUST UNIT
                      </td>
                      <td
                        className="font-normal title-font text-sm text-center border border-black pl-5 h-8"
                        colSpan="3"
                      >
                        SUPPLY EXHAUST UNIT
                      </td>
                    </tr>
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">W</td>
                      <td className="font-normal title-font text-md text-center border border-black">H</td>
                      <td className="font-normal title-font text-md text-center border border-black">HTOT</td>
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">W1</td>
                      <td className="font-normal title-font text-md text-center border border-black">H1</td>
                      <td className="font-normal title-font text-md text-center border border-black">H1TOT</td>
                    </tr>
                    {/* 1 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">700</td>
                      <td className="font-normal title-font text-md text-center border border-black">500</td>
                      <td className="font-normal title-font text-md text-center border border-black">620</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">1070</td>
                    </tr>
                    {/* 2 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">500</td>
                      <td className="font-normal title-font text-md text-center border border-black">620</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 900</td>
                      <td className="font-normal title-font text-md text-center border border-black">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">1070</td>
                    </tr>
                    {/* 3 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">600</td>
                      <td className="font-normal title-font text-md text-center border border-black">720</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 150</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 270</td>
                    </tr>
                    {/* 4 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 200</td>
                      <td className="font-normal title-font text-md text-center border border-black">600</td>
                      <td className="font-normal title-font text-md text-center border border-black">720</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 150</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 270</td>
                    </tr>
                    {/* 5 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 300</td>
                      <td className="font-normal title-font text-md text-center border border-black">700</td>
                      <td className="font-normal title-font text-md text-center border border-black">8 920</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 600</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 350</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 470</td>
                    </tr>
                    {/* 6 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 300</td>
                      <td className="font-normal title-font text-md text-center border border-black">800</td>
                      <td className="font-normal title-font text-md text-center border border-black">920</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 600</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 550</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 670</td>
                    </tr>
                    {/* 7 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 500</td>
                      <td className="font-normal title-font text-md text-center border border-black">800</td>
                      <td className="font-normal title-font text-md text-center border border-black">920</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 000</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 550</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 670</td>
                    </tr>
                    {/* 8 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 500</td>
                      <td className="font-normal title-font text-md text-center border border-black">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 070</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 000</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 850</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 970</td>
                    </tr>
                    {/* 9 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 700</td>
                      <td className="font-normal title-font text-md text-center border border-black">950</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 070</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 400</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 850</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 970</td>
                    </tr>
                    {/* 10 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">1 800</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 200</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 320</td>
                      <td className="font-normal title-font text-md text-center border border-black">3 600</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 350</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 470</td>
                    </tr>
                    {/* 11 */}
                    <tr className="border border-black h-8">
                      <td className="font-normal title-font text-sm text-center border border-black pl-5">2 000</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 300</td>
                      <td className="font-normal title-font text-md text-center border border-black">1 420</td>
                      <td className="font-normal title-font text-md text-center border border-black">4 000</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 550</td>
                      <td className="font-normal title-font text-md text-center border border-black">2 670</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
