import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function WallMountedPage() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSecondOpen, setIsSecondOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [desc5, setDesc5] = useState('');
  const [desc6, setDesc6] = useState('');
  const [desc7, setDesc7] = useState('');
  const [desc8, setDesc8] = useState('');
  const [desc9, setDesc9] = useState('');
  const [image, setImage] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [image5, setImage5] = useState('');
  const [image6, setImage6] = useState('');
  const [image7, setImage7] = useState('');
  const [image8, setImage8] = useState('');
  const [image9, setImage9] = useState('');
  const [image10, setImage10] = useState('');
  const [image11, setImage11] = useState('');
  const [image12, setImage12] = useState('');
  const [image13, setImage13] = useState('');
  const [image14, setImage14] = useState('');
  const [image15, setImage15] = useState('');
  const [image16, setImage16] = useState('');
  const [image17, setImage17] = useState('');
  const [image18, setImage18] = useState('');
  const [image19, setImage19] = useState('');
  const [image20, setImage20] = useState('');
  const [image21, setImage21] = useState('');
  const [image22, setImage22] = useState('');
  const [image23, setImage23] = useState('');
  const [image24, setImage24] = useState('');
  const [power, setPower] = useState('');
  const [heating, setHeating] = useState('');
  const [voltage, setVoltage] = useState('');
  const [flowRange, setFlowRange] = useState('');
  const [size, setSize] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeModal1 = () => {
    setIsSecondOpen(false);
  };
  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `${t('welcome.CU')}`,
      image: '/EmuraGrey1 .png',
      image2: '/EmuraGrey2.png',
      image3: '/EmuraGrey3.png',
      image4: '/EmuraGrey4.png',
      image5: '/EmuraGrey5.png',
      image6: '/EmuraGrey6.png',
      image7: 'EmuraBlack1.png',
      image8: 'EmuraBlack2.png',
      image9: 'EmuraBlack3.png',
      image10: 'EmuraBlack4.png',
      image11: 'EmuraBlack5.png',
      image12: 'EmuraBlack6.png',
      image13: 'EmuraWhite1.png',
      image14: 'EmuraWhite2.png',
      image15: 'EmuraWhite3.png',
      image16: 'EmuraWhite4.png',
      image17: 'EmuraWhite5.png',
      image18: 'EmuraWhite6.png',
      desc: `${t('welcome.emuraD1')}`,
      desc1: `${t('welcome.emuraD2')}`,
      desc2: `${t('welcome.emuraD3')}`,
      desc3: `${t('welcome.emuraD4')}`,
      desc4: `${t('welcome.emuraD5')}`,
      description: `${t('welcome.emuraDesc')}`,
      headImage: '/EmuraGrey1 .png',
      power: `${t('welcome.emuraPower')}`,
      heating: `${t('welcome.emuraHeating')}`,
      voltage: `${t('welcome.emuraVoltage')}`,
      flowRange: `${t('welcome.emuraFR')}`,
      size: `${t('welcome.emuraSize')}`,
    },
    {
      id: 2,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.FaCMAC')}`,
      image: '/StylishGrey1.png',
      image2: '/StylishGrey2.png',
      image3: '/StylishGrey3.png',
      image4: '/StylishGrey4.png',
      image5: '/StylishGrey5.png',
      image6: '/StylishGrey6.png',
      image7: 'StylishBlack1.png',
      image8: 'StylishBlack2.png',
      image9: 'StylishBlack3.png',
      image10: 'StylishBlack4.png',
      image11: 'StylishBlack5.png',
      image12: 'StylishBlack6.png',
      image13: 'StylishWhite1.png',
      image14: 'StylishWhite2.png',
      image15: 'StylishWhite3.png',
      image16: 'StylishWhite4.png',
      image17: 'StylishWhite5.png',
      image18: 'StylishWhite6.png',
      image19: 'StylishBlackGrey1.png',
      image20: 'StylishBlackGrey2.png',
      image21: 'StylishBlackGrey3.png',
      image22: 'StylishBlackGrey4.png',
      image23: 'StylishBlackGrey5.png',
      image24: 'StylishBlackGrey6.png',
      desc: `${t('welcome.stylishD1')}`,
      desc1: `${t('welcome.stylishD2')}`,
      desc2: `${t('welcome.stylishD3')}`,
      desc3: `${t('welcome.stylishD4')}`,
      desc4: `${t('welcome.stylishD5')}`,
      description: `${t('welcome.stylishDesc')}`,
      headImage: '/StylishGrey1.png',
      power: `${t('welcome.stylishPower')}`,
      heating: `${t('welcome.stylishHeating')}`,
      voltage: `${t('welcome.stylishVoltage')}`,
      flowRange: `${t('welcome.stylishFR')}`,
      size: `${t('welcome.stylishSize')}`,
    },
    {
      id: 3,
      headTitle:
        'Mongolia’s current tax system is the source of a package of tax laws that were enacted in 1992 and came into force on January 1, 1993.',
      title: `${t('welcome.MSS')}`,
      image: '/C1.png',
      image2: '/C2.png',
      image3: '/C3.png',
      image4: '/C4.png',
      image5: '/C5.png',
      image6: '/C6.png',
      desc: `${t('welcome.comforaD1')}`,
      desc1: `${t('welcome.comforaD2')}`,
      desc2: `${t('welcome.comforaD3')}`,
      desc3: `${t('welcome.comforaD4')}`,
      desc4: `${t('welcome.comforaD5')}`,
      description: `${t('welcome.comforaDesc')}`,
      headImage: '/C1.png',
      power: `${t('welcome.comforaPower')}`,
      heating: `${t('welcome.comforaHeating')}`,
      voltage: `${t('welcome.comforaVoltage')}`,
      flowRange: `${t('welcome.comforaFR')}`,
      size: `${t('welcome.comforaSize')}`,
    },
    {
      id: 4,
      headTitle: '',
      title: `${t('welcome.FAC')}`,
      image: '/us1.png',
      image2: '/us2.png',
      image3: '/us3.png',
      image4: '/us4.png',
      image5: '/us5.png',
      image6: '/us1.png',
      desc: `${t('welcome.ursaD1')}`,
      desc1: `${t('welcome.ursaD2')}`,
      desc2: `${t('welcome.ursaD3')}`,
      desc3: `${t('welcome.ursaD4')}`,
      desc4: `${t('welcome.ursaD5')}`,
      description: `${t('welcome.ursaDesc')}`,
      headImage: '/us1.png',
      power: `${t('welcome.ursaPower')}`,
      heating: `${t('welcome.ursaHeating')}`,
      voltage: `${t('welcome.ursaVoltage')}`,
      flowRange: `${t('welcome.ursaFR')}`,
      size: `${t('welcome.ursaSize')}`,
    },
    {
      id: 5,
      headTitle:
        'The institute of veterinary medicine was established as a branch of research institute of animal husbandry in 1961, and become and independent research institute of veterinary medicine in 1987. Currently  have 88 employees, includes 8 professors, 3 assistant professors, 7 scientific doctor, 35 PhDs and 22 senior level researchers',
      title: `${t('welcome.DAC')}`,
      image: '/pwm1.png',
      image2: '/pwm2.png',
      image3: '/pwm3.png',
      image4: '/pwm4.png',
      image5: '/pwm5.png',
      image6: '/pwm6.png',
      desc: `${t('welcome.perferaD1')}`,
      desc1: `${t('welcome.perferaD2')}`,
      desc2: `${t('welcome.perferaD3')}`,
      desc3: `${t('welcome.perferaD4')}`,
      desc4: `${t('welcome.perferaD5')}`,
      description: `${t('welcome.perferaDesc')}`,
      headImage: '/pwm1.png',
      power: `${t('welcome.perferaPower')}`,
      heating: `${t('welcome.perferaHeating')}`,
      voltage: `${t('welcome.perferaVoltage')}`,
      flowRange: `${t('welcome.perferaFR')}`,
      size: `${t('welcome.perferaSize')}`,
    },
    {
      id: 6,
      headTitle:
        'FTKM Series satisfies your cooling needs by offering a unique, total comfort experience for any lifestyle. New Coanda panel design creates a distinctive airflow pattern that assures cooling comfort for your home.',
      title: `${t('welcome.WM')}`,
      image: '/FTKMWM.png',
      desc: `${t('welcome.ftkmD1')}`,
      desc1: `${t('welcome.ftkmD2')}`,
      desc2: `${t('welcome.ftkmD3')}`,
      description: `${t('welcome.ftkmDesc')}`,
      headImage: '/FTKM.png',
      power: `${t('welcome.ftkmPower')}`,
      heating: `${t('welcome.ftkmHeating')}`,
      voltage: `${t('welcome.ftkmVoltage')}`,
      flowRange: `${t('welcome.ftkmFR')}`,
      size: `${t('welcome.ftkmSize')}`,
    },
  ];
  const data2 = [
    {
      id: 7,
      title: `${t('welcome.EPP')}`,
      image: '/AC_Energy-Pro-Plus.png',
      desc: `${t('welcome.EPPDetail1')}`,
      desc1: `${t('welcome.EPPDetail2')}`,
      desc2: `${t('welcome.EPPDetail3')}`,
      description: `${t('welcome.EPPDesc')}`,
      headImage: '/AC_Energy-Pro-Plus.png',
      power: `${t('welcome.EPPPower')}`,
      heating: `${t('welcome.EPPHeating')}`,
      voltage: `${t('welcome.EPPVoltage')}`,
      flowRange: `${t('welcome.EPPFR')}`,
      size: `${t('welcome.EPPSize')}`,
    },
    {
      id: 8,
      title: `${t('welcome.FM')}`,
      image: '/hisense-fresh-master-product-gallery-1.png',
      desc: `${t('welcome.FMDetail1')}`,
      desc1: `${t('welcome.FMDetail2')}`,
      desc2: `${t('welcome.FMDetail3')}`,
      description: `${t('welcome.FMDesc')}`,
      headImage: '/hisense-fresh-master-product-gallery-1.png',
      power: `${t('welcome.FreshMasterPower')}`,
      heating: `${t('welcome.FreshMasterHeating')}`,
      voltage: `${t('welcome.FreshMasterVoltage')}`,
      flowRange: `${t('welcome.FreshMasterFR')}`,
      size: `${t('welcome.FreshMasterSize')}`,
    },
    {
      id: 9,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.Perla')}`,
      image: '/perla.png',
      desc: `${t('welcome.PerlaDetail1')}`,
      desc1: `${t('welcome.PerlaDetail2')}`,
      desc2: `${t('welcome.PerlaDetail3')}`,
      desc3: `${t('welcome.PerlaDetail4')}`,
      description: `${t('welcome.PerlaDesc')}`,
      headImage: '/perla.png',
      power: `${t('welcome.PerlaPower')}`,
      heating: `${t('welcome.PerlaHeating')}`,
      voltage: `${t('welcome.PerlaVoltage')}`,
      flowRange: `${t('welcome.PerlaFR')}`,
      size: `${t('welcome.PerlaSize')}`,
    },
    {
      id: 10,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.EP')}`,
      image: '/HISENSE_ENERGY_PRO.png',
      desc: `${t('welcome.EPDetail')}`,
      desc1: `${t('welcome.EPDetail1')}`,
      desc2: `${t('welcome.EPDetail2')}`,
      desc3: `${t('welcome.EPDetail3')}`,
      desc4: `${t('welcome.EPDetail4')}`,
      desc5: `${t('welcome.EPDetail5')}`,
      desc6: `${t('welcome.EPDetail6')}`,
      desc7: `${t('welcome.EPDetail7')}`,
      desc8: `${t('welcome.EPDetail8')}`,
      desc9: `${t('welcome.EPDetail9')}`,
      description: `${t('welcome.EPDesc')}`,
      headImage: '/HISENSE_ENERGY_PRO.png',
      power: `${t('welcome.EPPower')}`,
      heating: `${t('welcome.EPHeating')}`,
      voltage: `${t('welcome.EPVoltage')}`,
      flowRange: `${t('welcome.EPFR')}`,
      size: `${t('welcome.EPSize')}`,
    },
    {
      id: 11,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.SP')}`,
      image: '/airCon_product01.png',
      desc: `${t('welcome.SPDetail1')}`,
      desc1: `${t('welcome.SPDetail2')}`,
      desc2: `${t('welcome.SPDetail3')}`,
      desc3: `${t('welcome.SPDetail4')}`,
      desc4: `${t('welcome.SPDetail5')}`,
      desc5: `${t('welcome.SPDetail6')}`,
      desc6: `${t('welcome.SPDetail7')}`,
      desc7: `${t('welcome.SPDetail8')}`,
      desc8: `${t('welcome.SPDetail9')}`,
      description: `${t('welcome.SPDesc')}`,
      headImage: '/airCon_product01.png',
      power: `${t('welcome.SilentiumProPower')}`,
      heating: `${t('welcome.SilentiumProHeating')}`,
      voltage: `${t('welcome.SilentiumProVoltage')}`,
      flowRange: `${t('welcome.SilentiumProFR')}`,
      size: `${t('welcome.SilentiumProSize')}`,
    },
    {
      id: 12,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.EzPure')}`,
      image: '/AC_Easy-Pure.png',
      desc: `${t('welcome.EzPureDetail')}`,
      desc1: `${t('welcome.EzPureDetail1')}`,
      desc2: `${t('welcome.EzPureDetail2')}`,
      description: `${t('welcome.EzPureDesc')}`,
      headImage: '/AC_Easy-Pure.png',
      power: `${t('welcome.EasyPurePower')}`,
      heating: `${t('welcome.EasyPureHeating')}`,
      voltage: `${t('welcome.EasyPureVoltage')}`,
      flowRange: `${t('welcome.EasyPureFR')}`,
      size: `${t('welcome.EasyPureSize')}`,
    },
  ];

  const openModal = (id) => {
    console.log('id', id);
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDescription(data1[id].description);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setImage(data1[id].image);
      setImage2(data1[id].image2);
      setImage3(data1[id].image3);
      setImage4(data1[id].image4);
      setImage5(data1[id].image5);
      setImage6(data1[id].image6);
      setImage7(data1[id].image7);
      setImage8(data1[id].image8);
      setImage9(data1[id].image9);
      setImage10(data1[id].image10);
      setImage11(data1[id].image11);
      setImage12(data1[id].image12);
      setImage13(data1[id].image13);
      setImage14(data1[id].image14);
      setImage15(data1[id].image15);
      setImage16(data1[id].image16);
      setImage17(data1[id].image17);
      setImage18(data1[id].image18);
      setImage19(data1[id].image19);
      setImage20(data1[id].image20);
      setImage21(data1[id].image21);
      setImage22(data1[id].image22);
      setImage23(data1[id].image23);
      setImage24(data1[id].image24);
      setHeating(data1[id].heating);
      setSize(data1[id].size);
      setFlowRange(data1[id].flowRange);
      setVoltage(data1[id].voltage);
      setPower(data1[id].power);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    console.log('id', id);
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDesc(data2[id].desc);
      setDesc1(data2[id].desc1);
      setDesc2(data2[id].desc2);
      setDesc3(data2[id].desc3);
      setDesc4(data2[id].desc4);
      setDesc5(data2[id].desc5);
      setDesc6(data2[id].desc6);
      setDesc7(data2[id].desc7);
      setDesc8(data2[id].desc8);
      setDesc9(data2[id].desc9);
      setImage(data2[id].image);
      setDescription(data2[id].description);
      setHeating(data2[id].heating);
      setSize(data2[id].size);
      setFlowRange(data2[id].flowRange);
      setVoltage(data2[id].voltage);
      setPower(data2[id].power);
      setIsSecondOpen(true);
      console.log('true');
    }
  };
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">
                Wall Mounted
              </p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">AIR CONDITIONING</h1>
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    HEATING
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Wall Mounted</h1>
                </button>
                <NavLink
                  key="MSS"
                  to="/MultiSplit"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Multi-Split Systems
                  </h1>
                </NavLink>
                <NavLink
                  key="CT"
                  to="/Cassette"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Cassette Type</h1>
                </NavLink>
                <NavLink
                  key="FAC"
                  to="/FloorAir"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Floor Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Ducted"
                  to="/Ducted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Ducted Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Condensing"
                  to="/Condensing"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Air Purifier</h1>
                </NavLink>
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
                <button
                  className={`hover:bg-white mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-2/3 h-2/3" src="daikin.png" alt="mrg" />
                </button>

                <button
                  className={`hover:bg-gray-50 mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 2' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  <img className="w-2/3 h-full fill-slate-600" src="hisense_.png" alt="mrg" />
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data1.headImage}
                                      alt="mrg"
                                      className={`${
                                        data1.id === 6
                                          ? 'w-4/5 h-1/2 bg-gray-50 hover:bg-white'
                                          : 'w-full h-full bg-gray-50 hover:bg-white'
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data2.headImage}
                                      alt="mrg"
                                      className="w-full h-full bg-gray-50 hover:bg-white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ProductModal
        data={data1}
        title={title}
        show={isOpen}
        desc={desc}
        description={description}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        image={image}
        image2={image2}
        image3={image3}
        image4={image4}
        image5={image5}
        image6={image6}
        image7={image7}
        image8={image8}
        image9={image9}
        image10={image10}
        image11={image11}
        image12={image12}
        image13={image13}
        image14={image14}
        image15={image15}
        image16={image16}
        image17={image17}
        image18={image18}
        image19={image19}
        image20={image20}
        image21={image21}
        image22={image22}
        image23={image23}
        image24={image24}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal}
      />
      <ProductModal
        data={data2}
        title={title}
        show={isSecondOpen}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        description={description}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal1}
      />
    </>
  );
}
