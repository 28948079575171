import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
// import ProjectModal from '../components/ProjectModal';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function AirHandling() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const data1 = [
    {
      id: 1,
      title: `EVO-S`,
      image: '/EVO-S.png',
      headImage: '/EVO-S.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXFA-A.html',
      url: '/EVO_S',
    },
    {
      id: 2,
      title: `EVO-H`,
      image: '/EVO-H.png',
      headImage: '/EVO-H.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXZQ-A.html',
      url: '/EVO_H',
    },
    {
      id: 3,
      title: `EVO-T`,
      image: '/EVO-T.png',
      headImage: '/EVO-T.png',
      // href: 'https://www.daikin.eu/en_us/products/product.table.html/FXFQ-A.html',
      url: '/EVO_T',
    },
  ];

  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">
                Modular Air Handling Unit
              </p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    Air Conditioning
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    Heating
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">VENTILATIONS</h1>
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Modular Air Handling Unit</h1>
                </button>
                <NavLink
                  key="Compact"
                  to="/Compact"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Compact Air Handling Unit
                  </h1>
                </NavLink>
                <NavLink
                  key="HeatRecovery"
                  to="/HeatRecovery"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Heat Recovery</h1>
                </NavLink>
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
                <button
                  className={`hover:bg-white mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-2/3 h-2/3" src="klimor.png" alt="mrg" />
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <NavLink key={'Pages'} to={`${data1.url}`}>
                            <div
                              aria-hidden="true"
                              className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                            >
                              <div className="h-3/4 w-full">
                                <div className="relative h-full w-full">
                                  <div className=" h-full w-full flex items-center justify-evenly ">
                                    <div className="w-full h-full flex items-center justify-center">
                                      <img
                                        src={data1.headImage}
                                        alt="mrg"
                                        className="w-full h-full object-contain bg-gray-50 hover:bg-white"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr className="solid" />
                              <div className="h-1/4 w-full flex items-center justify-center bg-white">
                                <div className="flex-1 p-5">
                                  <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                  <button type="button" className="mt-2 inline-flex text-sky-500">
                                    {t(`welcome.readMore`)} →
                                  </button>
                                </div>
                              </div>
                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
