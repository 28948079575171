import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function AirCurtain() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [desc5, setDesc5] = useState('');
  const [desc6, setDesc6] = useState('');
  const [desc7, setDesc7] = useState('');
  const [desc8, setDesc8] = useState('');
  const [desc9, setDesc9] = useState('');
  const [desc10, setDesc10] = useState('');
  const [desc11, setDesc11] = useState('');
  const [desc12, setDesc12] = useState('');
  const [desc13, setDesc13] = useState('');
  const [desc14, setDesc14] = useState('');
  const [image, setImage] = useState('');
  const [power, setPower] = useState('');
  const [heating, setHeating] = useState('');
  const [voltage, setVoltage] = useState('');
  const [flowRange, setFlowRange] = useState('');
  const [size, setSize] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeModalSecond = () => {
    setIsOpenSecond(false);
  };

  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `CYA-DK-F`,
      image: '/CYA-DK-F.png',
      desc: `Connectable to ERQ and VRV DX outdoor units`,
      desc1: `Unified indoor unit range for R-32 and R-410A`,
      desc2: `Free-hanging model (F): easy wall mounted installation`,
      desc3: `A payback period of less then 1.5 years compared to installing an electric air curtain`,
      desc4: `Provides virtually free air curtain heating via recovered heat from indoor units in cooling mode (in case of VRV heat recovery)`,
      desc5: `Easy and quick to install at reduced costs since no additional water systems, boilers and gas connections are required`,
      headImage: '/CYA-DK-F.png',
      power: '',
      heating: '0.1426-1.2 kW',
      voltage: '230 V',
      flowRange: '',
      size: '1000x774x370 mm',
    },
  ];
  const data2 = [
    {
      id: 7,
      headTitle: '',
      title: `VRV IV-S Series`,
      image: '/SILVER with heater (AG).png',
      description: `KAISAI Silver air curtains are effective devices at a competitive price. The curtains create an adequate air flow stopping the flow of cold or warm air flow to the inside, while creating a barrier for unwanted dust, smoke and insects. The devices can be mounted up to 3 meters above the floor. `,
      desc: `Powder coated housing`,
      desc1: `Regulated air flow`,
      desc2: `Wireless remote control`,
      desc3: `Triple safety technology`,
      desc4: `Optional air flow mode without heating`,
      desc5: `Remote Control`,
      desc6: `Cooling curtain technology`,
      desc7: `Energy-efficient PTC heater`,
      headImage: '/SILVER with heater (AG).png',
      power: '',
      heating: '6-14 kW',
      voltage: '400 V',
      flowRange: '',
      size: '1000,1500,2000x180x215 mm',
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDescription(data1[id].description);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setDesc5(data1[id].desc5);
      setDesc6(data1[id].desc6);
      setDesc7(data1[id].desc7);
      setDesc8(data1[id].desc8);
      setDesc9(data1[id].desc9);
      setDesc10(data1[id].desc10);
      setDesc11(data1[id].desc11);
      setDesc12(data1[id].desc12);
      setDesc13(data1[id].desc13);
      setDesc14(data1[id].desc14);
      setImage(data1[id].image);
      setHeating(data1[id].heating);
      setSize(data1[id].size);
      setFlowRange(data1[id].flowRange);
      setVoltage(data1[id].voltage);
      setPower(data1[id].power);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDescription(data2[id].description);
      setDesc(data2[id].desc);
      setDesc1(data2[id].desc1);
      setDesc2(data2[id].desc2);
      setDesc3(data2[id].desc3);
      setDesc4(data2[id].desc4);
      setDesc5(data2[id].desc5);
      setDesc6(data2[id].desc6);
      setDesc7(data2[id].desc7);
      setDesc8(data2[id].desc8);
      setDesc9(data2[id].desc9);
      setDesc10(data2[id].desc10);
      setImage(data2[id].image);
      setHeating(data2[id].heating);
      setSize(data2[id].size);
      setFlowRange(data2[id].flowRange);
      setVoltage(data2[id].voltage);
      setPower(data2[id].power);
      setIsOpenSecond(true);
      console.log('true');
    }
  };

  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">Air Curtain</p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    AIR CONDITIONING
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">HEATING</h1>
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Air Curtain</h1>
                </button>
                <NavLink
                  key="Heater"
                  to="/Heater"
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Heater</h1>
                </NavLink>
                {/* <NavLink
                  key="HeatPump"
                  to="/HeatPump"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Heat Pumps</h1>
                </NavLink> */}
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
              <button
                  className={`hover:bg-white mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-2/3 h-2/3 object-contain" src="daikin.png" alt="mrg" />
                </button>

                <button
                  className={`hover:bg-gray-50 mr-3 font-bold w-1/5 max-lg:w-1/2 h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 2' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  <img className="w-2/3 h-2/3 fill-slate-600 object-contain" src="kaisailogo.png" alt="mrg" />
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data1.headImage}
                                      alt="mrg"
                                      className='w-full h-full bg-gray-50 hover:bg-white'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data2.headImage}
                                      alt="mrg"
                                      className="w-full h-full bg-gray-50 hover:bg-white"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ProductModal
        title={title}
        show={isOpen}
        description={description}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        desc10={desc10}
        desc11={desc11}
        desc12={desc12}
        desc13={desc13}
        desc14={desc14}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModal}
      />

      <ProductModal
        title={title}
        show={isOpenSecond}
        description={description}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        desc7={desc7}
        desc8={desc8}
        desc9={desc9}
        desc10={desc10}
        desc11={desc11}
        image={image}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
        closeModal={closeModalSecond}
      />
    </>
  );
}
