import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function EvoTPlus() {
  const [t, i18n] = useTranslation('common');
  const [imageIndex, setImageIndex] = useState(0);
  const images = ['Klimor EVO-T+ 1.png','Klimor EVO-T+ 2.png'];

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 3000);

    return () => clearTimeout(timer);
  }, [imageIndex, images.length]);

  const changeImage = (increment) => {
    if (increment) {
      setImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    } else {
      setImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }
  };
  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center flex flex-col justify-center items-center h-full mb-10">
              <div className="w-full h-full flex flex-row justify-evenly items-center">
                <button onClick={() => changeImage(false)} className="text-4xl">
                  <img src="left-arrow.png" alt="mrg" className="w-10 max-sm:w-5 h-10 max-sm:h-5" />
                </button>
                <img src={images[imageIndex]} alt="mrg" className="w-1/3 h-1/3 max-lg:w-2/3 max-lg:h-2/3 bg-white object-contain" />
                <button onClick={() => changeImage(true)} className="text-4xl">
                  <img src="right-arrow.png" alt="mrg" className="w-10 max-sm:w-5 h-10 max-sm:h-5" />
                </button>
              </div>
              <p className="max-sm:text-4xl text-6xl title-font font-extralight color-white mb-5 w-full">Klimor EVO-T +</p>
              <p className="max-sm:text-xl text-3xl title-font font-extralight color-white mb-10 w-full">
              COMPACT SUSPENDED AHU WITH HEAT RECOVERY
              </p>
              <div className="w-full h-full flex max-sm:flex-col flex-row justify-evenly items-center mb-10">
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the office.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the office</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the hotel.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the hotel</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for the restaurant.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For the restaurant</p>
                </div>
                <div className="w-1/6 max-sm:w-3/4 max-sm:mb-5 flex flex-row justify-between items-center">
                  <img src="for apartment.png" alt="mrg" className="w-10 h-10" />
                  <p className="text-md title-font text-center font-extralight color-white">For your apartment</p>
                </div>
              </div>
              <p className="text-xl title-font font-extralight text-black mb-5 w-full h-full text-start">
                {t(`welcome.EVO-T-Plus-Desc`)}
              </p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-full h-full text-start">
                {t(`welcome.EVO-T-Plus-Desc2`)}
              </p>
              <p className="text-xl title-font font-extralight text-black mb-5 w-full h-full text-start">
                {t(`welcome.EVO-T-Plus-Desc3`)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
