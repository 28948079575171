import {
  MailOpenIcon,
  PhoneIcon,
  LocationMarkerIcon,
  ClockIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import DotSvg from '../components/DotSvg';
import Footer from '../layouts/Footer';

export default function ContactPage() {
  const [t, i18n] = useTranslation('common');
  return (
    <div className='mt-20 md:mt-20 lg:mt-20'>
      <div className="bg-white  overflow-hidden relative z-10 " id='overlayBack'>
        <div className="lg:px-20 ">
          <div className="flex flex-wrap lg:justify-between ">
            <div className="w-full lg:w-1/2 xl:w-6/12 px-16">
              <div className="max-w-[570px] mb-12 lg:mb-0">
                <span className="block mb-4 text-base text-[#073577] font-semibold"> {t("welcome.contactTitle")}</span>
                <h2
                  className="
                  text-dark
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
                >
                  {/* {t("welcome.contactMiniTitle")} */}
                </h2>
                <p className="text-base text-body-color leading-relaxed mb-10">
                {/* {t("welcome.contactDesc")} */}
                </p>

                <div className="mb-20">
                  <div className="mt-2 flex items-center text-sm text-gray-900">
                    <MailOpenIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700" aria-hidden="true" />
                    info@mrg.mn
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-900">
                    <PhoneIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700" aria-hidden="true" />
                    +(976) 72505038
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-900">
                    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700" aria-hidden="true" />
                    {t("welcome.address")}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-900">
                    <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-700" aria-hidden="true" />
                    {t("welcome.time")}: 9AM-6PM
                  </div>
                </div>
                <iframe
                  title="mrgMap"
                  className="w-full h-full border border-1 border-[#073577]"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21393.868933347447!2d106.931032!3d47.912517!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x98cc203bd1d327f7!2zTVJHIFByb2plY3RzIExMQyAvINCt0Lwg0JDRgCDQltC4INCf0YDQvtC20LXQutGC0YEg0KXQpdCa!5e0!3m2!1sen!2sus!4v1655281616582!5m2!1sen!2sus"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-5/12 px-16">
              <div className="bg-white relative rounded-lg p-8 sm:p-12 shadow-lg">
                <form>
                  <div className="mb-6">
                    <input
                      type="text"
                      placeholder={`${t("welcome.name")}`}
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="email"
                      placeholder={`${t("welcome.email")}`}
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    />
                  </div>
                  <div className="mb-6">
                    <input
                      type="text"
                      placeholder={`${t("welcome.phone")}`}
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    />
                  </div>
                  <div className="mb-6">
                    <textarea
                      rows="6"
                      placeholder={`${t("welcome.message")}`}
                      className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="
                        w-full
                        text-white
                        bg-[#073577]
                        rounded
                        border border-[#073577]
                        p-3
                        transition
                        hover:bg-opacity-90
                        "
                    >
                      {t("welcome.send")}
                    </button>
                  </div>
                </form>
                <div>
                  <span className="absolute -top-10 -right-9 z-[-1]">
                    <CircleSvg />
                  </span>
                  <span className="absolute -right-10 top-[90px] z-[-1]">
                    <DotSvg />
                  </span>
                  <span className="absolute -left-7 -bottom-7 z-[-1]">
                    <DotSvg />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const CircleSvg = (props) => (
  <svg width={100} height={100} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
      fill="#073577"
    />
  </svg>
);
