import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import ProjectModal from '../components/ProjectModal';
import Footer from '../layouts/Footer';

export default function CoolingHeating() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');
  const [hovered, setHovered] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [hovered5, setHovered5] = useState(false);
  const [hovered6, setHovered6] = useState(false);
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const data1 = [
    {
      id: 1,
      headTitle:
        'State Bank work towards delivering prompt, comprehensive, internationally accepted and state-of-the-art banking and financial services throughout the country, bolstering array of cooperation, and enabling stable business operation of our customers by its more than 500 branches, 348 ATMs and 3,400 point of sale (POS) merchants throughout Mongolia.',
      title: `${t('welcome.WM')}`,
      image: '/wallMounted.png',
      desc: `${t('welcome.sTdesc')}`,
      headImage: '/wallMounted.png',
    },
    {
      id: 2,
      headTitle:
        'Mongolia’s current tax system is the source of a package of tax laws that were enacted in 1992 and came into force on January 1, 1993.',
      title: `${t('welcome.MSS')}`,
      image: '/multi-split.png',
      desc: `${t('welcome.taXdesc')}`,
      headImage: '/multi-split.png',
    },
    {
      id: 3,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.FaCMAC')}`,
      image: '/f_c.png',
      desc: `${t('welcome.airRdesc')}`,
      headImage: '/f_c.png',
    },
    {
      id: 4,
      headTitle:
        'Khuvsgul-Erchim Khuch LLC Company is a supplier of electricity and heat power. It was founded in 1966 and is headquartered in Khuvsgul, Mongolia.',
      title: `${t('welcome.FAC')}`,
      image: '/Floor.png',
      desc: `${t('welcome.kHdesc')}`,
      headImage: '/Floor.png',
    },
    {
      id: 5,
      headTitle:
        'The institute of veterinary medicine was established as a branch of research institute of animal husbandry in 1961, and become and independent research institute of veterinary medicine in 1987. Currently  have 88 employees, includes 8 professors, 3 assistant professors, 7 scientific doctor, 35 PhDs and 22 senior level researchers',
      title: `${t('welcome.DAC')}`,
      image: '/DA.png',
      desc: `${t('welcome.veTdesc')}`,
      headImage: '/DA.png',
    },
    {
      id: 6,
      headTitle:
        'German quality RIVER VILLA, built according to German standards, has a 30,000m2 garden, a Cambridge garden, a lobby hall, a reception, a waiting room and service areas on one floor. The complex interior is a modern interior design with a wide selection of standard and duplex, penthouse apartment complex. “River villa” town with a park located along the Selbe river in the 15th khoroo of Khan-Uul district is a 3-block apartment complex for 615 families. The town will be commissioned in the second quarter of 2021.',
      title: `${t('welcome.CU')}`,
      image: '/condensinG.png',
      desc: `${t('welcome.riverVdesc')}`,
      headImage: '/condensinG.png',
    },
  ];
  const data2 = [
    {
      id: 7,
      headTitle:
        'State Bank work towards delivering prompt, comprehensive, internationally accepted and state-of-the-art banking and financial services throughout the country, bolstering array of cooperation, and enabling stable business operation of our customers by its more than 500 branches, 348 ATMs and 3,400 point of sale (POS) merchants throughout Mongolia.',
      title: `${t('welcome.HR')}`,
      image: '/HR-removebg-preview.png',
      desc: `${t('welcome.sTdesc')}`,
      headImage: '/HR-removebg-preview.png',
    },
    {
      id: 8,
      headTitle:
        'Mongolia’s current tax system is the source of a package of tax laws that were enacted in 1992 and came into force on January 1, 1993.',
      title: `${t('welcome.SMS')}`,
      image: '/CHU.png',
      desc: `${t('welcome.taXdesc')}`,
      headImage: '/CHU.png',
    },

    {
      id: 9,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.CHU')}`,
      image: '/coHEu.png',
      desc: `${t('welcome.airRdesc')}`,
      headImage: '/coHEu.png',
    },
  ];
  const data3 = [
    {
      id: 10,
      headTitle: `${t('welcome.tVdesc')}`,
      title: `${t('welcome.AHU')}`,
      image: '/AIU.png',
      desc: `${t('welcome.tVdesc')}`,
      headImage: '/AIU.png',
    },
    {
      id: 11,
      headTitle: `${t('welcome.ubRheadTitle')}`,
      title: `${t('welcome.CompactAHU')}`,
      image: '/CA.png',
      desc: `${t('welcome.CompactAHU')}`,
      headImage: 'CA.png',
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDesc(data1[id].desc);
      setImage(data1[id].image);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal2 = (id) => {
    if (id >= 0 && id < data2.length) {
      setTitle(data2[id].title);
      setDesc(data2[id].desc);
      setImage(data2[id].image);
      setIsOpen(true);
      console.log('true');
    }
  };
  const openModal3 = (id) => {
    if (id >= 0 && id < data3.length) {
      setTitle(data3[id].title);
      setDesc(data3[id].desc);
      setImage(data3[id].image);
      setIsOpen(true);
      console.log('true');
    }
  };
  // const openModalMain = (id) => {
  //   setTitle(dataMain[id].title);
  //   setDesc(dataMain[id].desc);
  //   setImage(dataMain[id].image);
  //   setIsOpen(true);
  // };
  return (
    <>
      <div className="h-fit w-full bg-gray-100 flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32">
          <div className="my-4 h-full w-10/12 ">
            <div className="text-center h-full">
              <p className="text-6xl title-font font-extralight color-white mb-10">Heater</p>
              <div className="flex justify-center h-1/3 mb-10">
                <button
                  className={`hover:bg-white font-bold w-1/3 h-24 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 ${
                    selectedButton === 'Button 1' ? 'bg-white text-white' : 'bg-gray-100 text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <h1 className="text-gray-600 hover:text-black text-2xl title-font font-normal">
                    {t(`welcome.Daikin`)}
                  </h1>
                </button>

                <button
                  className={`hover:bg-white font-bold w-1/3 h-24 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 ${
                    selectedButton === 'Button 2' ? 'bg-white text-white' : 'bg-gray-100 text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 2')}
                >
                  <h1 className="text-gray-600 hover:text-black text-2xl title-font font-normal">
                    {t(`welcome.Hisense`)}
                  </h1>
                </button>

                <button
                  className={`hover:bg-white font-bold w-1/3 h-24 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 ${
                    selectedButton === 'Button 3' ? 'bg-white text-white' : 'bg-gray-100 text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 3')}
                >
                  <h1 className="text-gray-600 hover:text-black text-2xl title-font font-normal">
                    {t(`welcome.Klimor`)}
                  </h1>
                </button>
              </div>
              <div className="flex justify-center h-1/3 mb-10">
                <NavLink
                  key="HeatRecovery"
                  to="/HeatRecovery"
                  className={`hover:bg-white rounded-l-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-gray-500 hover:text-black text-sm title-font font-normal">Heat Pump</h1>
                </NavLink>
                <NavLink
                  key="SMS"
                  to="/SMS"
                  className={`hover:bg-white rounded-r-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-gray-500 hover:text-black text-sm title-font font-normal"> Air Curtain</h1>
                </NavLink>
                <button
                  className={`hover:bg-white rounded-r-xl font-bold w-1/3 h-16 flex items-center justify-center flex-col object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100 bg-white text-white`}
                >
                  <h1 className="text-black hover:text-black text-md title-font font-normal">Heater</h1>
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid grid-cols-3 gap-2 h-2/3 w-full flex">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-white border-r-2 border-b-2 w-full h-96"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative hidden h-full w-full overflow-hidden hidden sm:block">
                                <div className="h-full w-full flex items-center justify-evenly object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100">
                                  <div className="w-full h-2/3 flex items-center justify-center">
                                      <img src={data1.headImage} alt="mrg" className="w-1/2 h-2/3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center">
                              <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <ArrowCircleRightIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 p-5 hidden sm:block">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 2' && (
                    <div className="grid grid-cols-3 gap-2">
                      {data2.map((data2, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal2(i)}
                            className="bg-white border-r-2 border-b-2 w-full h-96"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative hidden h-full w-full overflow-hidden hidden sm:block">
                                <div className=" h-full w-full flex items-center justify-evenly object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100">
                                  <div className="w-full h-2/3 flex items-center justify-center">
                                    <img src={data2.headImage} alt="mrg" className="w-1/2 h-2/3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center">
                              <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <ArrowCircleRightIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 p-5 hidden sm:block">
                                <h3 className="text-xl font-medium text-gray-700">{data2.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal2(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {selectedButton === 'Button 3' && (
                    <div className="grid grid-cols-2 gap-2">
                      {data3.map((data3, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal3(i)}
                            className="bg-white border-r-2 border-b-2 w-full h-96"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative hidden h-full w-full overflow-hidden hidden sm:block">
                                <div className=" h-full w-full flex items-center justify-evenly object-scale-down object-center scale-110 transition-all duration-400 hover:scale-100">
                                  <div className="w-full h-2/3 flex items-center justify-center">
                                    <img src={data3.headImage} alt="mrg" className="w-1/2 h-2/3" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center">
                              <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <ArrowCircleRightIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 p-5 hidden sm:block">
                                <h3 className="text-xl font-medium text-gray-700">{data3.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal3(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ProjectModal title={title} show={isOpen} desc={desc} image={image} closeModal={closeModal} />
    </>
  );
}
