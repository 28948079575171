import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import ProjectModal from '../components/ProjectModal';
import Footer from '../layouts/Footer';

export default function ProjectsPage() {
  const [t, i18n] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');

  const dataMain = [
    {
      id: 1,
      headTitle: `${t('welcome.eTheadTitle')}`,
      title: `${t('welcome.eTtitle')}`,
      image: '/Pro1Head.png',
      desc: `${t('welcome.eTdesc')}`,
      headImage: '1p.png',
    },
  ];

  const data = [
    {
      id: 1,
      headTitle: `${t('welcome.tVdesc')}`,
      title: `${t('welcome.tVtitle')}`,
      image: '/Pro2Desc.jpeg',
      desc: `${t('welcome.tVdesc')}`,
      headImage: '/9p.png',
    },
    {
      id: 2,
      headTitle: `${t('welcome.ubRheadTitle')}`,
      title: `${t('welcome.ubRtitle')}`,
      image: '/Pro3Desc.jpeg',
      desc: `${t('welcome.ubRdesc')}`,
      headImage: '6p.png',
    },
    {
      id: 3,
      headTitle: '',
      title: `${t('welcome.eTJtitle')}`,
      image: '/Pro4Desc.jpeg',
      desc: `${t('welcome.eTJdesc')}`,
      headImage: '/10p.png',
    },
    {
      id: 4,
      headTitle:
        'MSM Group LLC was established in Mongolia in 1998. They employ a dedicated and professional team of 350 members. The company is active in 6 main areas of business.',
      title: `${t('welcome.mStitle')}`,
      image: '/Pro5Desc.jpeg',
      desc: `${t('welcome.mSdesc')}`,
      headImage: '/11p.png',
    },
    {
      id: 5,
      headTitle:
        'The foundation of Thermal Power Plant No. 3 was laid on December 3, 1966, and the first plant was put into operation on December 10, 1968, to distribute thermal energy to offices and apartments in the capital city.',
      title: `${t('welcome.th3title')}`,
      image: '/Pro6Desc.jpeg',
      desc: `${t('welcome.th3desc')}`,
      headImage: '/14p.png',
    },
    {
      id: 6,
      headTitle:
        'The foundation of TPP-4 was laid in 1979, and in 1983 it began to develop and distribute electricity and heat. Currently, the plant has an installed capacity of 772 MW and an installed heat capacity of 1,373 Gcal / h, which alone supplies about 58.7 percent of the Central Region’s electricity consumption and about 54.8 percent of Ulaanbaatar’s thermal energy.',
      title: `${t('welcome.th4title')}`,
      image: '/Pro7Desc.png',
      desc: `${t('welcome.th4desc')}`,
      headImage: '/4p.png',
    },
    {
      id: 8,
      headTitle:
        'State Bank work towards delivering prompt, comprehensive, internationally accepted and state-of-the-art banking and financial services throughout the country, bolstering array of cooperation, and enabling stable business operation of our customers by its more than 500 branches, 348 ATMs and 3,400 point of sale (POS) merchants throughout Mongolia.',
      title: `${t('welcome.sTtitle')}`,
      image: '/Pro8Desc.jpeg',
      desc: `${t('welcome.sTdesc')}`,
      headImage: '/7p.png',
    },
    {
      id: 9,
      headTitle:
        'Mongolia’s current tax system is the source of a package of tax laws that were enacted in 1992 and came into force on January 1, 1993.',
      title: `${t('welcome.taXtitle')}`,
      image: '/Pro9Desc.jpeg',
      desc: `${t('welcome.taXdesc')}`,
      headImage: '/15p.png',
    },

    {
      id: 10,
      headTitle: `${t('welcome.airRheadTitle')}`,
      title: `${t('welcome.airRtitle')}`,
      image: '/niseh.jpg',
      desc: `${t('welcome.airRdesc')}`,
      headImage: '/nisehHead.jpg',
    },
    {
      id: 11,
      headTitle:
        'Khuvsgul-Erchim Khuch LLC Company is a supplier of electricity and heat power. It was founded in 1966 and is headquartered in Khuvsgul, Mongolia.',
      title: `${t('welcome.kHtitle')}`,
      image: '/tsahilgaan.png',
      desc: `${t('welcome.kHdesc')}`,
      headImage: '/tsahilgaan.png',
    },
    {
      id: 12,
      headTitle:
        'The institute of veterinary medicine was established as a branch of research institute of animal husbandry in 1961, and become and independent research institute of veterinary medicine in 1987. Currently  have 88 employees, includes 8 professors, 3 assistant professors, 7 scientific doctor, 35 PhDs and 22 senior level researchers',
      title: `${t('welcome.veTtitle')}`,
      image: '/mlemDesc.jpeg',
      desc: `${t('welcome.veTdesc')}`,
      headImage: '/mlemHead.jpeg',
    },
    {
      id: 13,
      headTitle:
        'German quality RIVER VILLA, built according to German standards, has a 30,000m2 garden, a Cambridge garden, a lobby hall, a reception, a waiting room and service areas on one floor. The complex interior is a modern interior design with a wide selection of standard and duplex, penthouse apartment complex. “River villa” town with a park located along the Selbe river in the 15th khoroo of Khan-Uul district is a 3-block apartment complex for 615 families. The town will be commissioned in the second quarter of 2021.',
      title: `${t('welcome.riverVtitle')}`,
      image: '/Pro13Desc.jpeg',
      desc: `${t('welcome.riverVdesc')}`,
      headImage: '/1p.png',
    },
    {
      id: 14,
      headTitle:
        'Duudii Auction LLC is launching a new car auction business in the Mongolian car trade market in order to start trading Japanese standard cars and ensure commercial safety.',
      title: `${t('welcome.duuDVtitle')}`,
      image: '/DudiiDesc.jpeg',
      desc: `${t('welcome.duuDVdesc')}`,
      headImage: '/DudiiHead.jpeg',
    },
    {
      id: 7,
      headTitle:
        'D.Boldbaatar, the founder, famous translator, doctor and professor of “Gegee” school, “Gegee” educational center, established in 2015, is still operating successfully. “Gegee” educational center started teaching Mongolian ger in China in 2015, which was very successful for the students. The program was developed and taught in ENGLISH and JAPANESE. and staff',
      title: `${t('welcome.geeGtitle')}`,
      image: '/Pro15Desc.png',
      desc: `${t('welcome.geeGdesc')}`,
      headImage: '/12p.png',
    },
    {
      id: 15,
      headTitle: `${t('welcome.mglseoheadTitle')}`,
      title: `${t('welcome.mglseotitle')}`,
      image: '/mglseo.png',
      desc: `${t('welcome.mglseodesc')}`,
      headImage: '/mglseo.png',
    },
    {
      id: 16,
      headTitle: `${t('welcome.PremheadTitle')}`,
      title: `${t('welcome.Premtitle')}`,
      image: '/Prem.png',
      desc: `${t('welcome.Premdesc')}`,
      headImage: 'Prem.png',
    },
    {
      id: 17,
      headTitle: `${t('welcome.AutoTeeverheadTitle')}`,
      title: `${t('welcome.AutoTeevertitle')}`,
      image: '/autoteever.png',
      desc: `${t('welcome.AutoTeeverdesc')}`,
      headImage: '/autoteever.png',
    },
    {
      id: 18,
      headTitle: `${t('welcome.TsahilgaanheadTitle')}`,
      title: `${t('welcome.Tsahilgaantitle')}`,
      image: '/tsahilgaan.png',
      desc: `${t('welcome.Tsahilgaandesc')}`,
      headImage: 'tsahilgaan.png',
    },
  ];

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (id) => {
    setTitle(data[id].title);
    setDesc(data[id].desc);
    setImage(data[id].image);
    setIsOpen(true);
  };

  const openModalMain = (id) => {
    setTitle(dataMain[id].title);
    setDesc(dataMain[id].desc);
    setImage(dataMain[id].image);
    setIsOpen(true);
  };

  const [text, setText] = useState('');
  const [fullText, setFullText] = useState(`${t('welcome.projectsTitle')}`);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 40);
    }
  }, [index]);

  useEffect(() => {
    setText(`${t('welcome.projectsTitle')}`);
  }, [i18n.language]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="relative" id="overlayBack">
        <div>
          <div className="py-5 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h1 className="title-font font-extralight text-gray-900 text-1xl text-center sm:text-4xl md:text-4xl ">
                  {text}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <img
            src="/projectCompanies.png"
            className="bg-white object-cover lg:w-3/5 md:w-11/12 sm:h-3/5 sm:w-3/5"
            alt="Mrg About Page"
          />
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="max-w-7xl">
            <div className="flex min-h-screen items-center justify-center lg:p-10 bg-white">
              <div className="container grid max-w-screen-xl gap-8 lg:grid-cols-2 lg:grid-rows-2">
                <div
                  aria-hidden="true"
                  onClick={() => openModalMain(0)}
                  className="row-span-2 flex flex-col rounded-md border border-slate-200"
                >
                  <div className="h-1/2 flex-1">
                    <img src={dataMain[0].image} className="w-full object-cover object-right-top" alt="2.png" />
                  </div>
                  <div className="p-5">
                    <h3 className="text-xl font-medium text-gray-700">{dataMain[0].title}</h3>
                    <p className="mt-2 text-slate-500">{dataMain[0].headTitle}</p>
                    <button type="button" onClick={() => openModalMain(0)} className="mt-2 inline-flex text-sky-500">
                      {t(`welcome.readMore`)} →
                    </button>
                  </div>
                </div>
                {data.map((data, i) => {
                  return (
                    <>
                      <div
                        aria-hidden="true"
                        onClick={() => openModal(i)}
                        className="flex border-b lg:border md:border border-slate-200 lg:rounded-md lg:border pb-5 lg:pb-0 md:pb-0 lg:border-slate-200"
                      >
                        <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                          <div className="absolute inset-0  flex items-center justify-center">
                            <img
                              src={data.headImage}
                              className="h-5/6 w-9/10 lg:h-full lg:w-full object-cover object-left-top lg:object-left-top"
                              alt=""
                            />
                          </div>
                        </div>

                        <div className="flex-1 lg:p-10 pl-2.5 lg:hidden md:hidden lg:block">
                          <h3 className="text-[12px] lg:text-xl font-medium text-gray-700">{data.title}</h3>
                          <p className="text-[10px] mt-2 text-slate-500">{data.desc.substring(0, 147).concat('...')}</p>
                        </div>
                        <div className="relative h-full w-1/5 overflow-hidden lg:hidden md:hidden lg:block">
                          <div className="absolute inset-0 flex items-center justify-center">
                            <ArrowCircleRightIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>

                        <div className="flex-1 p-5 hidden sm:block">
                          <h3 className="text-xl font-medium text-gray-700">{data.title}</h3>
                          <p className="mt-2 text-slate-500">{data.desc.substring(0, 97).concat('...')}</p>
                          <button type="button" onClick={() => openModal(1)} className="mt-2 inline-flex text-sky-500">
                            {t(`welcome.readMore`)} →
                          </button>
                        </div>
                        <div className="relative hidden h-full w-2/5 overflow-hidden hidden sm:block">
                          <div className="absolute inset-0">
                            <img src={data.headImage} className="h-full w-full object-cover object-center" alt="" />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ProjectModal title={title} show={isOpen} desc={desc} image={image} closeModal={closeModal} />
    </>
  );
}
