import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import { NavLink } from 'react-router-dom';
import Footer from '../layouts/Footer';
import ProductModal from '../components/productModal';

export default function Condensing() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [desc1, setDesc1] = useState('');
  const [desc2, setDesc2] = useState('');
  const [desc3, setDesc3] = useState('');
  const [desc4, setDesc4] = useState('');
  const [desc5, setDesc5] = useState('');
  const [desc6, setDesc6] = useState('');
  const [image, setImage] = useState('');
  const [power, setPower] = useState('');
  const [heating, setHeating] = useState('');
  const [voltage, setVoltage] = useState('');
  const [flowRange, setFlowRange] = useState('');
  const [size, setSize] = useState('');
  const [selectedButton, setSelectedButton] = useState('Button 1');

  const handleButtonClick = (buttonText) => {
    setSelectedButton(buttonText);
    setText('');
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const data1 = [
    {
      id: 1,
      headTitle: '',
      title: `MC30YB`,
      image: '/MC30YB.svg',
      desc: `Perfect for rooms up to 46m²`,
      desc1: `High-performance electrostatic HEPA filter`,
      desc2: `The deodorising filter absorbs odours`,
      desc3: `Market-leading filter lifetime of 10 years`,
      desc4: `Quiet Mark certified for whisper-quiet operation`,
      headImage: '/MC30YB.svg',
      power: ``,
      heating: `0.008-0.025 kW`,
      voltage: `220-240/220-230 V`,
      flowRange: `23-46 m2`,
      size: `450x270x270 mm`,
    },
    {
      id: 2,
      headTitle: '',
      title: `MCK55W`,
      image: '/MCK55W.svg',
      desc: `Can purify rooms of up to 82 m²`,
      desc1: `High-performance electrostatic HEPA filter`,
      desc2: `The deodorising filter absorbs odours`,
      desc3: `Market-leading filter lifetime of 10 years`,
      desc4: `Quiet Mark certified for whisper-quiet operation`,
      headImage: '/MCK55W.svg',
      power: ``,
      heating: `0.011-0.058 kW`,
      voltage: `220-240/220-230 V`,
      flowRange: `41-82 m2`,
      size: `700x270x270 mm`,
    },
    {
      id: 3,
      headTitle: '',
      title: `MCK80Z`,
      image: '/MCK80Z.svg',
      desc: `Onecta app: control your indoor from any location with an app, via your local network or internet.`,
      desc1: `Air purification for large spaces up to 124m², such as residential and light commercial applications`,
      desc2: `Pure air thanks to Daikin ‘Catch and Clean’ approach in decomposing harmful substances`,
      desc3: `Intuitive display design with coloured Daikin Eye, visual way of informing users about indoor air quality`,
      desc4: `High performance electrostatic HEPA filter which doesn’t clog easily, hence causes low pressure loss`,
      desc5: `No need to change the filter for ten years, resulting in low maintenance costs`,
      desc6: `One of the most silent Air Purifier range on the European market`,
      headImage: '/MCK80Z.svg',
      power: ``,
      heating: `0.009-0.080 kW`,
      voltage: `220-240/220-230 V`,
      flowRange: `62 m2`,
      size: `630x315x315 mm`,
    },
  ];

  const openModal = (id) => {
    if (id >= 0 && id < data1.length) {
      setTitle(data1[id].title);
      setDesc(data1[id].desc);
      setDesc1(data1[id].desc1);
      setDesc2(data1[id].desc2);
      setDesc3(data1[id].desc3);
      setDesc4(data1[id].desc4);
      setDesc5(data1[id].desc5);
      setDesc6(data1[id].desc6);
      setImage(data1[id].image);
      setHeating(data1[id].heating);
      setSize(data1[id].size);
      setFlowRange(data1[id].flowRange);
      setVoltage(data1[id].voltage);
      setPower(data1[id].power);
      setIsOpen(true);
      console.log('true');
    }
  };

  return (
    <>
      <div className="h-fit w-full bg-white flex flex-col">
        <div className="flex justify-center items-center h-fit mt-10 mb-32 ">
          <div className="my-4 h-full xl:w-10/12 2xl:w-4/6 max-sm:w-10/12 max-xl:w-11/12">
            <div className="text-center h-full">
              <p className="text-6xl max-sm:text-3xl title-font font-extralight color-white mb-10 w-full">
                Air Purifier
              </p>
              <div className="max-lg:hidden flex justify-center h-1/3 mb-2">
                <div className="flex justify-evenly items-center w-full h-full">
                  <NavLink
                    key="AirConditioning"
                    to="/AirConditioning"
                    className={`hover:bg-white shadow-lg rounded-tl-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    <h1 className="text-indigo-900 text-xl title-font font-normal">AIR CONDITIONING</h1>
                  </NavLink>
                  <NavLink
                    key="Heating"
                    to="/Heating"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    HEATING
                  </NavLink>
                  <NavLink
                    key="Ventilations"
                    to="/Ventilations"
                    className={`hover:bg-white text-black shadow-lg hover:text-indigo-900 text-xl title-font font-normal rounded-tr-xl font-bold w-1/3 h-16 flex items-center justify-around flex-col bg-gray-50 text-white`}
                  >
                    VENTILATIONS
                  </NavLink>
                </div>
              </div>
              <div className="max-lg:hidden flex justify-evenly h-full bg-cyan mb-12">
                <NavLink
                  key="WM"
                  to="/WallMounted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Wall Mounted</h1>
                </NavLink>
                <NavLink
                  key="MSS"
                  to="/MultiSplit"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Multi-Split Systems
                  </h1>
                </NavLink>
                <NavLink
                  key="CT"
                  to="/Cassette"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">Cassette Type</h1>
                </NavLink>
                <NavLink
                  key="FAC"
                  to="/FloorAir"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Floor Air Conditioners
                  </h1>
                </NavLink>
                <NavLink
                  key="Ducted"
                  to="/Ducted"
                  className={`hover:bg-white font-bold w-1/3 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-black hover:text-indigo-900 text-sm title-font font-normal">
                    Ducted Air Conditioners
                  </h1>
                </NavLink>
                <button
                  className={`hover:bg-white font-bold w-1/2 h-16 flex items-center justify-center flex-col bg-gray-50 shadow-lg`}
                >
                  <h1 className="text-indigo-900 text-lg title-font font-normal">Air Purifier</h1>
                </button>
              </div>
              <div className="flex justify-start h-1/3 mb-4 w-full">
                <button
                  className={`hover:bg-white mr-3 font-bold w-1/5 max-lg:w-1/2  h-20 flex items-center justify-around flex-col ${
                    selectedButton === 'Button 1' ? ' shadow-xl bg-gray-50 text-white' : 'bg-white text-black'
                  }`}
                  onClick={() => handleButtonClick('Button 1')}
                >
                  <img className="w-2/3 h-2/3" src="daikin.png" alt="mrg" />
                </button>
              </div>
              {selectedButton && (
                <>
                  {selectedButton === 'Button 1' && (
                    <div className="grid max-sm:grid-cols-1 max-xl:grid-cols-2 grid-cols-3 gap-2">
                      {data1.map((data1, i) => {
                        return (
                          <div
                            aria-hidden="true"
                            onClick={() => openModal(i)}
                            className="bg-gray-50 hover:bg-white border-r-2 border-b-2 w-full h-96 object-scale-down object-center scale-y-95 transition-all duration-400 hover:scale-100 shadow-md"
                          >
                            <div className="h-3/4 w-full">
                              <div className="relative h-full w-full">
                                <div className=" h-full w-full flex items-center justify-evenly ">
                                  <div className="w-full h-full flex items-center justify-center">
                                    <img
                                      src={data1.headImage}
                                      alt="mrg"
                                      className='w-full h-full bg-gray-50 hover:bg-white'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="solid" />
                            <div className="h-1/4 w-full flex items-center justify-center bg-white">
                              <div className="flex-1 p-5">
                                <h3 className="text-xl font-medium text-gray-700">{data1.title}</h3>
                                <button
                                  type="button"
                                  onClick={() => openModal(1)}
                                  className="mt-2 inline-flex text-sky-500"
                                >
                                  {t(`welcome.readMore`)} →
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {/* <ACModal title={title} show={isOpen} desc={desc} desc1={desc1} desc2={desc2} desc3={desc3} image={image} closeModal={closeModal} /> */}
      <ProductModal
        title={title}
        show={isOpen}
        desc={desc}
        desc1={desc1}
        desc2={desc2}
        desc3={desc3}
        desc4={desc4}
        desc5={desc5}
        desc6={desc6}
        image={image}
        closeModal={closeModal}
        power={power}
        heating={heating}
        voltage={voltage}
        flowRange={flowRange}
        size={size}
      />
    </>
  );
}
