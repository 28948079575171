import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../layouts/Footer';
import MediaModal from '../components/MediaModal';

export default function MediaPage() {
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState('');
  const [fullText, setFullText] = useState(`${t('welcome.media')}`);
  const [index, setIndex] = useState(0);

  const data = [
    {
      id: 1,
      headTitle: `${t('welcome.media1MiniDesc')}`,
      title: `${t('welcome.media1Title')}`,
      image: '/Media3.jpeg',
      popImage: '/MediaPop3.jpg',
      desc: `${t('welcome.media1Desc')}`,
    },
    {
      id: 2,
      headTitle: `${t('welcome.media2MiniDesc')}`,
      title: `${t('welcome.media2Title')}`,
      image: '/Media2.jpeg',
      popImage: '/MediaPop2.jpg',
      desc: `${t('welcome.media2Desc')}`,
    },
    {
      id: 3,
      headTitle: `${t('welcome.media3MiniDesc')}`,
      title: `${t('welcome.media3Title')}`,
      image: '/Media1.jpeg',
      popImage: '/MediaPop1.jpg',
      desc: `${t('welcome.media3Desc')}`,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (id) => {
    setTitle(data[id].title);
    setDesc(data[id].desc);
    setImage(data[id].popImage);
    setIsOpen(true);
  };

  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 40);
    }
  }, [index]);

  useEffect(() => {
    setText(`${t('welcome.media')}`);
  }, [i18n.language]);

  return (
    <>
      <div id="overlayBack">
        <div className="py-5 bg-white">
          <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
            <div className="relative">
              <h1 className="title-font font-extralight text-gray-900 text-1xl text-center sm:text-3xl md:text-3xl ">
                {text}
              </h1>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <div className="max-w-7xl">
            <div className="py-10 bg-white px-10">
              <div className="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
                {data.map((item, index) => {
                  return (
                    <div className="bg-white rounded-2xl shadow-xl px-8 py-12 lg:px-12 lg:px-8">
                      <img src={item.image} alt={item.image} height="50%" />
                      <div className="mt-5 space-y-4">
                        <h3 className="text-2xl font-semibold text-[#073577]">{item.title}</h3>
                        <p className="mb-6">{item.headTitle.substring(0, 97).concat('...')}</p>
                        <button
                          type="button"
                          onClick={() => openModal(index)}
                          className="block font-medium text-[#073577]"
                        >
                          {t('welcome.readMore')}
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <MediaModal title={title} show={isOpen} desc={desc} image={image} closeModal={closeModal} />
    </>
  );
}
