import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../layouts/Footer';
import CompaniesModal from '../components/CompaniesModal';

export default function CompaniesPage() {
  const [t, i18n] = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const data = [
    {
      id: 1,
      title: `${t('welcome.mainSubComMrg')}`,
      image: 1,
      desc: `${t('welcome.comConMrgDesc')}`,
    },
    {
      id: 2,
      title: `${t('welcome.mainSubComCore')}`,
      image: 2,
      desc: `${t('welcome.comCfDesc')}`,
    },
    {
      id: 3,
      title: `${t('welcome.mainSubComIng')}`,
      image: 3,
      desc: `${t('welcome.comInDesc')}`,
    },
  ];

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (id) => {
    setTitle(data[id].title);
    setDesc(data[id].desc);
    setImage(data[id].image);
    setIsOpen(true);
  };

  const [text, setText] = useState('');
  const [fullText, setFullText] = useState(`${t('welcome.mainSubCom')}`);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[index]);
        setIndex(index + 1);
      }, 40);
    }
  }, [index]);

  useEffect(() => {
    setText(`${t('welcome.mainSubCom')}`);
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="overlayBack">
      <div>
        <div className="py-5 bg-white">
          <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
            <div className="relative">
              <h1 className="title-font font-extralight text-gray-900 text-1xl text-center sm:text-4xl md:text-4xl ">
                {text}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <div className="max-w-7xl">
          <section className="py-20 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                  <button
                    type="button"
                    onClick={() => openModal(0)}
                    className="inline-flex items-center border border-transparent text-md leading-4 rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    <img className="rounded-lg shadow-xl cursor-pointer" src="/Com2.png" alt="" />
                  </button>
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                  <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                    {t('welcome.mainSubComMrg')}
                  </p>
                  <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
                    {t('welcome.comCon')} / {t('welcome.comMine')}
                  </h3>
                  <p className="mt-5 text-lg text-gray-700 text md:text-left">{t('welcome.comConMrg')}</p>
                  <button type="button" onClick={() => openModal(0)} className="mt-2 inline-flex text-sky-500">
                    {t('welcome.readMore')} →
                  </button>
                </div>
              </div>
              <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                  <button
                    type="button"
                    onClick={() => openModal(1)}
                    className="inline-flex items-center border border-transparent text-md leading-4 rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    <img className="rounded-lg shadow-xl cursor-pointer" src="/Com4.png" alt="" />
                  </button>
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                  <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                    {t('welcome.mainSubComCore')}
                  </p>
                  <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">{t('welcome.comIt')}</h3>
                  <p className="mt-5 text-lg text-gray-700 text md:text-left">{t('welcome.comCf')}</p>
                  <button type="button" onClick={() => openModal(1)} className="mt-2 inline-flex text-sky-500">
                    {t('welcome.readMore')} →
                  </button>
                </div>
              </div>
              <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                  <button
                    type="button"
                    onClick={() => openModal(2)}
                    className="inline-flex items-center border border-transparent text-md leading-4 rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    <img className="rounded-lg shadow-xl cursor-pointer" src="/Com6.png" alt="" />
                  </button>
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                  <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                    {t('welcome.mainSubComIng')}
                  </p>
                  <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">{t('welcome.comFs')} </h3>
                  <p className="mt-5 text-lg text-gray-700 text md:text-left">{t('welcome.comIn')}</p>
                  <button type="button" onClick={() => openModal(2)} className="mt-2 inline-flex text-sky-500">
                    {t('welcome.readMore')} →
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <CompaniesModal title={title} show={isOpen} desc={desc} image={image} closeModal={closeModal} />
      <Footer />
    </div>
  );
}
